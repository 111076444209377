.appointment-details {
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .appointment {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid $grey-light;
    }

    .label-value-row {
      display: flex;
      margin: 5px 0;

      .appt-label {
        color: $grey;
        margin-right: 10px;
      }
    }
  }
}
