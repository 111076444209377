.store-select-table {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 20px;
  }

  .no-store-found {
    margin-top: 40px;

    &-msg {
      color: $grey;
    }
  }

  .loading-stores {
    margin-top: 50px;
  }

  .row-card {
    height: 60px;
    margin-top: 20px;
    display: flex;
    .shop-info {
      padding: 5px;
      max-width: 400px;
      border: 1px solid $grey-light;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding-right: 0;

      .left {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        .name {
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .created-at {
          color: $grey;
          font-size: 13px;
        }
      }

      .right {
        white-space: nowrap;
        padding: 0 20px;
        flex: 0.2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $grey-light;
        height: 100%;
      }
    }

    .selectButton {
      margin-left: 10px;
      width: 60px;
      display: flex;
      border: 1px solid $grey-light;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in;

      &:hover,
      &.selected {
        cursor: pointer;
        background: $blue;
        color: white;
        border: 1px solid transparent;
      }

      &.disabled {
        background: white;
        cursor: no-drop;
        color: $grey;
      }
    }
  }
}
