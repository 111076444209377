#organization-user-create-page {
  .page-header {
    text-align: center;
    font-weight: 300;
    font-size: 36px;
    border: none;
    margin: 0 0 50px;
  }

  .user-create-form {
    max-width: 330px;
    margin: auto;

    .ant-select {
      width: 100%;
      max-width: 296px;
    }

    .create-user-btn-wrapper {
      margin: 40px auto;
      display: flex;
    }
  }
}
