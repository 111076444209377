.sierraWrapper .desktopNavigation {
  > .navigationMenu {
    display: table-cell;
    vertical-align: top;
    left: 0;
    min-width: 200px;
    float: none;
    background: $white;
    padding: 51px 0 20px;
    transition: left 0.3s;
    position: static;
    border: 1px solid $grey-light;
    z-index: 2;
    @include box-shadow(rgba($black, 0.05));
    @include xl {
      width: 200px;
    }
    @include lg {
      left: -200px;
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      min-width: 200px;
    }
    .navigationMenuLink {
      &.active:before {
        border-color: $yellow;
        opacity: 1;
        transform: scaleY(1);
      }
    }
  }
}

.navigationMenu {
  margin: 10px 10px 10px 0;
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  right: 10px;
  left: 0px;
  width: auto;

  .navigationMenuLink {
    padding-left: 10px !important;
    color: darken($grey, 1%);
    position: relative;
    display: block;
    text-overflow: inherit;
    @include transition(background 0.3s ease, border-color 0.3s ease);
    margin: 0;

    &:not(:last-child) {
      margin: 0;
    }

    a {
      color: inherit;
    }

    .ant-menu-submenu-title {
      padding-left: 10px !important;
      margin: 0 0 0 -10px;
    }

    &:hover,
    &.active {
      background: $offwhite;
      text-decoration: none;
    }
    &.active {
      color: darken($grey, 20%);
    }

    &:after {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-right: 5px solid transparent;
      transform: scaleY(0.0001);
      opacity: 0;
      @include transition(all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1));
    }

    .navigationMenuLinkIcon {
      max-width: 38px;
      display: inline-block;
      width: 30px;
      font-size: 14px;
      vertical-align: middle;
    }

    .navigationMenuLinkText {
      display: inline-block;
      width: 80%;
      font-size: 13px;
      font-weight: 400;
      line-height: 11px;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .navigationSubMenu {
    border-left: none;
    padding: 0 !important;
    .ant-menu-submenu-title {
      background: $white;
      margin: 0;
      > span {
        display: inline-block;
        width: 85%;
      }
    }
  }
  .ant-menu {
    border-right: 0 !important;
  }
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $grey-light;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: darken($grey-light, 15%);
    border-radius: 10px;
  }
}
