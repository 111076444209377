#organization-create-edit-page {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    margin: 10px 0 24px;
  }

  .organization-create-edit-page-header {
    margin: 0 0 32px;
  }

  .organization-create-edit-page-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: solid 1px $grey-light;
    border-radius: 5px;
    flex-direction: column;

    .row {
      display: flex;
      margin: 0 30px;

      @include md {
        flex-direction: column;
        .upload-logo {
          padding-right: 50px;
        }
      }

      .upload-logo,
      .assign-permissions-form,
      .address-form {
        flex: 0.5;
        margin: 10px 25px;

        .header {
          margin: 35px 0 20px;
          padding: 0 0 8px;
          font-size: 18px;
          font-weight: 500;
          border-bottom: solid 1px $black;
        }
      }
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 40px 0;
    }
  }
}
