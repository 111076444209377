#organization-store-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .organization-store-page-header {
    font-size: 36px;
  }

  .page-wrapper {
    width: 480px;

    .shopify-logo-wrapper {
      display: flex;
      width: 480px;

      .shopify-logo {
        width: 120px;
        height: fit-content;
      }
    }

    .header-wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 12px;

      .header {
        font-size: 21px;
        font-weight: 500;
      }
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 0 12px;

      .shopify-domain-input {
        width: 100%;
        height: auto;
        color: #000000;
        background-color: #ffffff;
        padding: 16px 3px 8px;
        border: none;
        border-bottom: 2px #9b9b9b solid;
        margin: 0;

        &:focus {
          border-color: transparent;
          border-bottom: 2px #9b9b9b solid;
          box-shadow: none;
        }
      }

      .help-text {
        margin: 16px 0 24px;
        font-size: 10px;
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: center;

      .submit-button {
        &:hover {
          background: $yellow;
          color: inherit;
          border-color: transparent;
        }

        &:disabled {
          color: #adadad;
        }
      }
    }

    .stores-table-wrapper {
      display: flex;
      flex-direction: column;
      margin: 32px 0 0;
    }
  }
}
