.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $blue;
  }
  .delete-btn {
    color: $red;
    font-size: 20px;
  }
}
