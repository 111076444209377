#organization-invoices-index-page {
  .organization-invoices {
    .invoices-header {
      .customer-specific-options {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .customer-name {
          font-size: 18px;
          cursor: pointer;
          color: $blue;

          &:hover {
            opacity: 0.8;
            text-decoration: underline;
          }
        }
      }

      .title-with-search {
        display: flex;
        align-items: center;

        .page-title {
          font-weight: 300;
          font-size: 36px;
          margin-right: 30px;
        }
      }
    }
  }
}
