.yellow-next-button {
  margin: 20px 0;

  &.with-step {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  > .flex-row {
    justify-content: center;

    > .wording {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 10px;
    }

    > button {
      padding: 5px 50px;
      height: auto;
      border-width: 2px;
      border-color: $yellow;
      font-weight: 400;
      @include border-radius();

      &:hover,
      &:focus {
        color: $charcoal;
        background: $yellow;
      }
    }
  }
}
