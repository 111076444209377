.createInvoiceForm {
}

.createInvoiceForm a {
  cursor: pointer;
}

.standardCard .createInvoiceForm hr {
  margin: 20px 0;
}

.createInvoiceForm input[type="number"]::-webkit-inner-spin-button,
.createInvoiceForm input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** Invoice Items Section **/

.createInvoiceForm .invoiceItems table {
  width: 100%;
}

.createInvoiceForm .invoiceItems table > tbody > tr > th,
.createInvoiceForm .invoiceItems table > tbody > tr > td {
  padding-bottom: 10px;
  position: relative;
}

.createInvoiceForm .invoiceItems table > tbody > tr > td > p {
  margin: 0;
}

.createInvoiceForm .invoiceItems table > tbody > tr > td:nth-child(1),
.createInvoiceForm .invoiceItems table > tbody > tr > th:nth-child(1) {
  width: auto;
}

.createInvoiceForm .invoiceItems table > tbody > tr > td:nth-child(2),
.createInvoiceForm .invoiceItems table > tbody > tr > th:nth-child(2) {
  width: 83px;
  padding-left: 15px;
}

.createInvoiceForm .invoiceItems table > tbody > tr > td:nth-child(3),
.createInvoiceForm .invoiceItems table > tbody > tr > th:nth-child(3) {
  width: 130px;
  padding-left: 15px;
}

.createInvoiceForm .invoiceItems table > tbody > tr > td:nth-child(4),
.createInvoiceForm .invoiceItems table > tbody > tr > th:nth-child(4) {
  width: 136px;
  text-align: right;
  padding-left: 15px;
}

.createInvoiceForm .invoiceItems table > tbody > tr > td:nth-child(5),
.createInvoiceForm .invoiceItems table > tbody > tr > th:nth-child(5) {
  width: 12px;
  padding-left: 15px;
}

.createInvoiceForm .invoiceItems table tr.summaryRow button {
  background: none;
  border: none;
}

.createInvoiceForm .invoiceItems table tr.summaryRow button:hover {
  color: #4a90e2;
  cursor: pointer;
}

.createInvoiceForm .invoiceItems table tr.summaryRow:nth-last-child(3) > td {
  padding-top: 20px;
}

.createInvoiceForm .invoiceItems table tr.summaryRow input[type="number"] {
  max-width: 65px;
}

.createInvoiceForm
  .invoiceItems
  table
  tr.summaryRow
  input[type="number"]
  + span {
  position: absolute;
  top: 6px;
  right: 65px;
}

.createInvoiceForm .invoiceItems table tr p.warning > small {
  color: red;
}

.createInvoiceForm .invoiceItems a.disabled {
  cursor: not-allowed;
}

.createInvoiceForm .invoiceItems button:disabled {
  cursor: not-allowed;
}

/** Memo Section **/

.createInvoiceForm .memo > div > p:nth-child(1) {
  display: inline;
  text-align: left;
}

.createInvoiceForm .memo > div > p:nth-last-child(1) {
  float: right;
}

.createInvoiceForm .memo p.warning > small {
  color: red;
}

.createInvoiceForm .memo textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 35px;
  max-height: 150px;
}

/** Billing Section **/

.createInvoiceForm .billing > div {
  margin-bottom: 10px;
}

.createInvoiceForm .billing > div > label {
  font-weight: normal;
}

.createInvoiceForm .billing > div > label > input {
  margin-right: 20px;
}

.createInvoiceForm .billing > .dueDate {
  margin-left: 32.8px;
}

.createInvoiceForm .billing > .dueDate > div {
  position: relative;
}

.createInvoiceForm .billing > .dueDate input[type="number"] {
  display: inline-block;
  width: 75px;
  margin-right: 10px;
}

.createInvoiceForm .billing > .dueDate input[type="number"] + span {
  position: absolute;
  top: 5px;
  left: 35px;
}

.createInvoiceForm .billing > .dueDate > div > p {
  display: inline;
}

/** Submit Row **/

.createInvoiceForm .submitRow {
  text-align: right;
}

.createInvoiceForm .submitRow > div {
  display: inline-block;
  margin-left: auto;
  margin-right: 30px;
}

.createInvoiceForm .submitRow > button {
  display: inline-block;
  margin-left: auto;
}
