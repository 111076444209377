.notes-table {
  .no-notes {
    margin: 24px 0 0;
  }

  .notes-action-button {
    width: fit-content;
    box-shadow: none;
    border: 0;
    background: transparent;

    &:not(:last-child) {
      margin: 0 0 8px 0;
    }
  }
}
