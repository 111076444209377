.user-create-input {
  margin: 30px auto;

  .form-label {
    font-size: 12px;
    font-weight: 300;
    margin: 5px;
  }

  .input-with-verification {
    display: flex;

    .check-wrap {
      width: 34px;

      .anticon {
        color: $white;
        margin: 4px 0 4px 10px;
        background: $green;
        border-radius: 25px;
        padding: 5px;
      }
    }
  }
}
