.product-type-tag {
  font-size: 11px;
  background: none;
  color: $grey;
  padding: 4px 20px;
  text-align: center;
  border-radius: 15px;

  &.orange {
    background: $orange-light;
    color: $orange-strong;
    border: 1px solid $orange-strong;
  }

  &.blue {
    background: $blue-extra-light;
    color: $blue;
    border: 1px solid $blue;
  }
}
