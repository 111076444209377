.inventory-grid {
  border-top: 1px solid $grey-light;
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @include md {
    flex-direction: column;
    align-items: center;
  }

  .image-box-wrapper {
    margin: 0 20px 20px 0;

    @include md {
      margin: 10px 0;
    }
  }
}

.image-wrapper {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .no-image-icon {
    font-size: 80px;
    color: $grey;
  }
}
