.edit-user-role-modal {
  text-align: center;
  .field-input {
    .label {
      color: $grey;
      font-weight: 300;
      font-size: 13px;
      margin: 0;
      padding: 0;
      margin-right: 10px;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .action-buttons {
    margin-top: 20px;
    text-align: center;

    .ant-btn {
      margin: 0 5px;
    }
  }
}
