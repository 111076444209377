.singleInvoice {
  .button-row {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  text-align: center;
  margin: 20px 15px;
  @include lg {
    margin: 10px 0px;
  }
  * {
    font-size: 14px;
  }
  p {
    line-height: 25px;
    margin: 0;
  }
  .greyButton.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
  .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($grey-light, 0.5);
  }
  .invoiceBtn {
    border: 2px solid transparent;
    @include border-radius($radius-big);
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding: 2px 6px;
    color: $charcoal;
    background: $yellow;
    @include transition(background 0.3s, border-color 0.3s);
    &:hover {
      background: $grey-light !important;
      border-color: $grey-light !important;
    }
    &.yellowTransparentBtn {
      background: transparent;
      border-color: $yellow;
    }
  }
  .invoiceHeader {
    width: 100%;
    max-width: 400px;
    margin: auto;
    * {
      text-align: left;
    }
    > img {
      width: 100%;
      max-width: 250px;
      margin-bottom: 35px;
      height: 50.237px;
    }
    > table {
      width: 100%;
      max-width: 400px;
      margin: auto;
      text-transform: capitalize;
      > tbody > tr > td {
        width: 50%;
        text-align: left;
      }
    }
    .memo-title {
      margin-top: 15px;
    }
    .memo-body {
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 15px;
    }
  }
  table {
    > tbody > tr > td,
    > tbody > tr > th {
      &:nth-child(1) {
        text-align: left;
      }
      &:nth-last-child(1) {
        width: 100px;
        text-align: right;
      }
    }
    &.lineItemsTable {
      width: 100%;
      max-width: 400px;
      margin: auto;
    }
    &.totalsTable {
      width: 100%;
      max-width: 400px;
      margin: auto;
      > tbody > tr > td:nth-last-child(1) {
        width: 100px;
      }
      > tbody > tr > td:nth-last-child(2) {
        width: 100px;
        text-align: left;
      }
    }
  }
  .status-change-buttons {
    align-items: center;
    max-width: 400px;
    margin: 10px auto;

    p {
      margin-right: 10px;
    }

    button {
      margin-right: 10px;
    }

    i {
      color: $blue;
    }
  }
}
