$yellow: #ffd33d;
$blue: #4a90e2;
$blue-light: #e6f7ff;
$blue-extra-light: #f0f7ff;
$red: #ff3a52;
$orange: #f0ad4e;
$orange-strong: #f5a623;
$orange-light: #fff6e8;
$green: #37bc9b;
$white: #ffffff;
$offwhite: #f4f6f8;
$black: #000000;
$charcoal: #404041;
$grey: #9b9b9b;
$grey-light: #eeeeee;
$grey-mid: darken($grey-light, 10%);
$grey-dark: #828282;
$grey-background: #fbfbfb;
$grass-green: #6fcf97;
