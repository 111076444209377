#org-user-role-create {
  .roles-create-header {
    .page-title {
      font-weight: 300;
      font-size: 36px;
      margin: 25px 30px 0 0;
    }

    .organization-name {
      color: $grey;
      font-size: 18px;
    }
    margin-bottom: 20px;
  }

  .role-create-form {
    display: flex;
    margin-top: 20px;

    .header {
      margin-bottom: 25px;
      padding: 5px;
      border-bottom: 1px solid $black;
      font-weight: 300;
      font-size: 24px;
    }

    .name-input,
    .assign-permissions-form {
      flex: 0.5;
      margin-right: 40px;
    }
  }

  .role-submit-btn {
    display: block;
    margin: 60px auto 0;
  }
}
