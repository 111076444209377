.fulfillment-organization-table {
  margin-top: 20px;
  border: 1px solid $grey-light;
  padding: 20px 20px 0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 20px;

    .title {
      font-size: 20px;
    }
  }

  th {
    white-space: nowrap;
  }

  td {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .order-id-cell,
    .wro-id-cell,
    .asn-id-cell,
    .product-id-cell,
    .clickable {
      color: $blue;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .see-full-list-btn {
      color: $blue;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .data-table {
    * {
      @include scrollbar();
    }
  }
}
