.appointment-details-item-table {
  .appointment-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      font-weight: 500;
    }
  }
  .filter-container {
    padding: 0 20px;
    border-top: thin solid $grey-light;
    border-bottom: thin solid $grey-light;
    margin: 0 -20px;
    flex-wrap: wrap;

    > div {
      padding: 15px 0;

      &:first-child {
        flex-shrink: 0;
      }
      &:last-child {
        flex-wrap: wrap;
      }
    }
  }

  .table-filter {
    border: thin solid $grey;
    @include border-radius();
    margin: 5px 20px 5px 0;
    cursor: pointer;
    transition: box-shadow 0.25s ease;

    &:hover {
      @include box-shadow();
    }

    .name-container {
      width: 150px;
      padding: 0 10px;

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .quantity-container {
      width: 30px;
      transition: background-color 0.25s ease;

      p {
        color: $white;
      }
    }

    .green {
      background-color: $green;
    }

    .blue {
      background-color: $blue;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;

      p {
        margin: 0;
      }
    }
  }

  .edit-section {
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    flex-wrap: wrap;

    input {
      border: 1px solid darken($grey-light, 5%);
      margin: 10px 0;
    }

    button {
      margin: 5px;
    }
  }

  .ant-table-selection-column {
    width: 50px;
  }

  table.fixed {
    table-layout: fixed;
  }
  td.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    padding: 3px 15px;
  }
}
