.service-area-card {
  @include box-shadow();
  padding: 20px;

  .card-spin {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-row {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .row-title {
      margin-right: 10px;
    }
  }
}
