.w-100 {
  width: 100%;
}

.w-100-md {
  @include md {
    display: block;
    width: 100% !important;
  }
}

.w-100-sm {
  @include sm {
    display: block;
    width: 100% !important;
  }
}

.w-90 {
  width: 90%;
}

.w-90-md {
  @include md {
    width: 90%;
  }
}

.w-90-sm {
  @include sm {
    width: 90%;
  }
}

.w-80 {
  width: 80%;
}

.w-80-md {
  @include md {
    width: 80%;
  }
}

.w-80-sm {
  @include sm {
    width: 80%;
  }
}

.w-75 {
  width: 75%;
}

.w-75-md {
  @include md {
    width: 75%;
  }
}

.w-75-sm {
  @include sm {
    width: 75%;
  }
}

.w-70 {
  width: 70%;
}

.w-70-md {
  @include md {
    width: 70%;
  }
}

.w-70-sm {
  @include sm {
    width: 70%;
  }
}

.w-60 {
  width: 60%;
}

.w-60-md {
  @include md {
    width: 60%;
  }
}

.w-60-sm {
  @include sm {
    width: 60%;
  }
}

.w-50 {
  width: 50%;
}

.w-50-md {
  @include md {
    width: 50%;
  }
}

.w-50-sm {
  @include sm {
    width: 50%;
  }
}

.w-45 {
  width: 45%;
}

.w-45-md {
  @include md {
    width: 45%;
  }
}

.w-45-sm {
  @include sm {
    width: 45%;
  }
}

.w-40 {
  width: 40%;
}

.w-40-md {
  @include md {
    width: 40%;
  }
}

.w-40-sm {
  @include sm {
    width: 40%;
  }
}

.w-33 {
  width: 33%;
}

.w-33-md {
  @include md {
    width: 33%;
  }
}

.w-33-sm {
  @include sm {
    width: 33%;
  }
}

.w-30 {
  width: 30%;
}

.w-30-md {
  @include md {
    width: 30%;
  }
}

.w-30-sm {
  @include sm {
    width: 30%;
  }
}

.w-25 {
  width: 25%;
}

.w-25-md {
  @include md {
    width: 25%;
  }
}

.w-25-sm {
  @include sm {
    width: 25%;
  }
}

.w-10 {
  width: 10%;
}

.w-10-md {
  @include md {
    width: 10%;
  }
}

.w-10-sm {
  @include sm {
    width: 10%;
  }
}

.w-0 {
  width: 0%;
  @include md {
    width: 0%;
  }
}

.w-0-md {
  @include md {
    width: 0%;
  }
}

.w-0-sm {
  @include sm {
    width: 0%;
  }
}
