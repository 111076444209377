#fulfillment-role-management-page {
  .org-name-with-add-btn {
    display: flex;
    justify-content: space-between;

    .organization-name {
      font-size: 18px;
      color: $blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-title {
    font-weight: 300;
    font-size: 36px;
    margin: 25px 30px 20px 0;
  }
}
