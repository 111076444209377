.page-container {
  background: $white;
  // min-height: 100vh;
  width: 100%;
  padding: 30px;
  border: 1px solid $grey-light;
  overflow: hidden;
  @include xl {
    margin-top: -25px;
    border-right: none;
    padding: 10px 15px;
  }
  @include lg {
    border-left: none;
  }
}

.transparent-page-container {
  width: 100%;
  overflow: hidden;
  @include xl {
    margin-top: -25px;
    border-right: none;
    padding: 10px 15px;
  }
  @include lg {
    border-left: none;
  }
}

.tbl {
  display: table;
  width: 100%;
  table-layout: fixed;
  .w-101 {
    width: 100.2%;
  }
}

.tbl-cell {
  display: table-cell;
}

.inline-block {
  display: inline-block;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-rev {
  display: flex;
  flex-direction: row-reverse;
}

.inl-flex-col {
  display: inline-flex;
  flex-direction: column;
}

.inl-flex-row {
  display: inline-flex;
  flex-direction: row;
}

.align-left {
  align-items: flex-start;
}

.align-right {
  align-items: flex-end;
}

.align-center {
  align-self: center;
}

.flex-fill {
  flex-basis: 0;
  flex-grow: 1;
}

.m-align {
  vertical-align: middle;
}

.t-align {
  vertical-align: top;
}

.b-align {
  vertical-align: bottom;
}

.breakWord {
  display: inline;
  overflow-wrap: break-word;
}

.hr {
  margin: 10px -50px;
}

.margin0 {
  margin: 0;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop5 {
  margin-top: 5px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.border-radius-none {
  border-radius: 0px;
}

.border-radius-big {
  border-radius: 15px;
}

.border-black {
  border-color: $charcoal;
}

.link:hover {
  text-decoration: none;
}

.disabled {
  position: relative;
  opacity: 0.5;
  pointer-events: none;
  @include transition(all 0.3s linear);
}

button:disabled,
input:disabled,
a:disabled,
div[disabled] button,
div[disabled] input {
  pointer-events: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.display-none {
  display: none;
}
