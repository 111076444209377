.manifest-layout {
  .manifest-header {
    padding: 30px;

    .logo-with-appt-type {
      display: flex;
      justify-content: space-between;

      .logo-container {
        .sc-logo {
          width: 300px;
        }
      }
      .appointment-type {
        font-weight: 900;
        font-size: 36px;
        padding-left: 70px;
        text-align: right;
      }
    }

    .appointment-info {
      margin: 20px 0 0;

      .details-section {
        display: flex;
        justify-content: space-between;

        .details-column {
          box-sizing: border-box;
          width: 20%;

          &:nth-child(even) {
            margin-right: 20px;
          }

          .detail-segment {
            padding: 10px 0;

            .title {
              font-weight: bold;
            }
          }
        }
      }

      .immediate-assistance {
        width: 100%;
        margin: 15px 0 0;
        font-size: 12px;
      }
    }
  }

  .manifest-table-wrapper {
    border-top: 5px solid black;
    width: 100%;
    padding: 20px 30px;
    .manifest-table {
      width: 100%;

      tr {
        height: 40px;
      }

      td {
        border-bottom: 1px solid #414141;
      }
    }
  }

  .manifest-footer {
    display: flex;
    flex-direction: column;
    margin: 0 30px;

    .time-in-out,
    .sign-section {
      display: flex;
      margin-top: 90px;
    }

    .sign-section {
      justify-content: space-between;

      .fill-section {
        width: 280px;
        border-top: 1px solid $black;
      }
    }
  }
}
