.edit-contact-info-modal {
  .field-input {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    .label {
      color: $grey;
      font-weight: 300;
      font-size: 13px;
      margin: 0;
      padding: 0;

      &::after {
        content: " *";
        color: $red;
      }
    }
  }

  .action-buttons {
    margin-top: 20px;
    text-align: center;

    .ant-btn {
      margin: 0 5px;
    }
  }
}
