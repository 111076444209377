.order-edit-supplies-table {
  margin-top: 20px;

  .table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid darken($grey-light, 10%);

    .title {
      font-size: 20px;
    }
  }
}
