#product-index-page {
  .orders {
    .back-order-icon {
      color: $red;
      font-size: 20px;
    }

    .order-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .top {
      display: flex;
      flex-direction: column;

      .customer-specific-options {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .customer-name {
          color: $blue;
          cursor: pointer;
          font-size: 20px;
        }

        .top-buttons {
          @include sm {
            margin-bottom: 10px;
          }
          .add-product-btn {
            border-radius: 15px;
            border: 1px solid $blue;
            margin-right: 10px;
            &:last-child {
              margin-right: 0px;
            }
            span {
              color: $blue;
            }
            &:hover {
              background: $blue;
              span {
                color: white;
              }
            }
          }
        }
      }

      .title-with-search {
        display: flex;
        align-items: center;

        .page-title {
          font-size: 25px;
          margin-right: 20px;
        }
      }
    }

    .check-box-group {
      margin-top: 20px;
    }
  }
}
