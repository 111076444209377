.complete-shopify-install-page {
  margin: 0 auto;

  .ant-col {
    padding-bottom: 25px;
  }

  .install-progress {
    margin-top: 20px;
    display: flex;

    .ant-spin {
      margin-right: 15px;
    }
  }

  .shopify-logo {
    width: 120px;
  }
}
