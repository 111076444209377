html,
body {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #404041;
  background-color: #f4f6f8;
}

#root {
  height: 100%;
  min-height: 100%;
}

#root > div {
  height: inherit;
}

body {
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: #404041;
  margin: 0;
}

input:focus {
  outline: none;
}

button,
li,
p {
  color: #404041;
}

button:focus,
button:active {
  outline: none;
}

div.loader-container.absolute,
div.loader-container.absolute > .loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.35);
  z-index: 3;
}

div.loader-container.absolute > .col-xs-12 {
  margin-top: 0 !important;
  float: none;
}

@media print {
  .manifest {
    page-break-before: always !important;
    display: block;
  }
  .manifest-top .manifest-blackBar,
  .manifest-bottom .manifest-blackBar {
    background-color: #414141 !important;
    -webkit-print-color-adjust: exact !important;
  }
  .manifest-info h3 {
    color: Black !important;
    -webkit-print-color-adjust: exact !important;
  }
  .manifest label {
    color: black !important;
    font-weight: bold !important;
    -webkit-print-color-adjust: exact !important;
  }
  .content {
    margin: 0 !important;
    padding: 0 !important;
  }
  .navigationMenu,
  .topBar {
    display: none !important;
  }
  .manifest-note button {
    display: none !important;
  }
}

@page {
  margin: 10mm 0;
  width: 8.5in;
  height: 11in;
}

.displayNone {
  display: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
}

.print {
  display: none;
}

.fill {
  height: 100%;
  min-height: 100%;
}

.sierraWrapper {
  height: inherit;
  padding: 0;
  position: relative;
}

.sierraWrapper .row.fill {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: inherit;
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .sierraWrapper.active .row.fill:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(40, 40, 41, 0.7);
    z-index: 1;
  }
}

.sierraWrapper .content {
  display: table-cell;
  float: none;
}

.sierraWrapper .content #left-column {
  padding-right: 0;
}

.content {
  padding: 95px 50px 40px;
}

@media (max-width: 1200px) {
  .content {
    border-left: none;
    padding: 75px 0 0;
  }
}

.standardCard {
  background-color: white;
  padding: 20px 20px 20px 20px;
  min-width: 300px;
  border: 1px solid #eee;
  margin-bottom: 15px;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -o-transition: 1s ease;
  -ms-transition: 1s ease;
  transition: 1s ease;
}

.standardCard.autoWidth {
  min-width: 100px;
}

.standardCard.notification {
  padding: 12px;
  background: rgba(218, 68, 83, 0.8);
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}

.standardCard.notification > i.fa {
  display: inline-block;
  vertical-align: bottom;
  font-size: 1.5em;
  margin-right: 0.5em;
}

.standardCard.notification > span {
  display: inline-block;
  vertical-align: bottom;
}

.standardCardOutline {
  background-color: white;
  padding: 20px 20px 20px 20px;
  min-width: 200px;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(154, 179, 189);
  border-radius: 5px;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -o-transition: 1s ease;
  -ms-transition: 1s ease;
  transition: 1s ease;
}

@media (max-width: 991px) {
  .standardCard {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 450px) {
  .standardCard {
    min-width: auto;
  }
}

.standardCard hr {
  margin: 0;
}

.standardCardTopButton {
  margin-top: -10.4px;
}

.standardCardTopButton:hover {
  text-decoration: underline;
}

.standardCardTitle {
  position: relative;
}

.standardCardTitleButton {
  position: absolute;
  right: -5px;
  top: -15px;
  z-index: 1;
}

.loadOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: white;
  background-color: rgba(40, 40, 41, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.noPadding {
  padding: 0;
}

.rowMargin {
  margin-bottom: 15px;
}

.rowMarginSmall {
  margin-bottom: 8.5px;
}

.rowMarginTiny {
  margin-bottom: 3px;
}

.fittedTextArea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.heavy {
  font-weight: 500;
}

.superHeavy {
  font-weight: 600;
}

.paddingFive {
  padding: 5px;
}

.separateCardHeader {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.tableFilterButton {
  padding: 0 10px 0 10px;
  margin-bottom: 15px;
  background-color: transparent;
  border-color: transparent;
}

.modalContent {
  min-height: 100px;
}

.loader {
  min-height: 60px;
}

.briefProduct {
  width: 100%;
  min-width: 230px;
}

.briefProductDetails {
  margin-left: 10px;
}

.smallButtonSeparation {
  margin-left: 10px;
}

.briefProductImageCell {
  width: 70px;
}

.briefProductImage {
  height: auto;
  min-height: 70px;
  width: 70px;
}

.datepickerContainer {
  width: 250px;
  height: 264px;
  display: inline-block;
}

.addressDivider {
  margin-top: 8px;
  margin-bottom: 8px;
}

.customerIcon {
  margin-right: 10px;
  width: 56px;
  height: 56px;
  display: inline-block;
}

.orderButton,
.orderButton:focus,
.orderButton:active,
.orderButton:active:focus {
  outline: none;
}

.orderButton button {
  background-color: #f4f6f8;
}

.loginTopMargin {
  margin-top: 100px;
}

.stripebutton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #ffd33d;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.stripeform {
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.StripeElement {
  display: block;
  margin: 20px 0 20px 0;
  max-width: 600px;
  padding: 10px 10px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripelabel {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.simplebox {
  outline: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: none;
}

.inventoryItem {
  background-color: #f4f6f8;
  min-height: 84.2px;
  /* box-shadow: 0px 3px 12px rgba(0,0,0,0.15); */
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.inventoryItem:hover {
  cursor: pointer;
}

.inventoryItemStatus {
  padding: 4px 8px 4px 8px;
  border-radius: 3px;
  width: auto;
}

.backgroundYellow {
  background: #ffd33d;
}

.backgroundGreen {
  background: #8cc152;
}

.backgroundBlue {
  background: #4a89dc;
}

.backgroundOrange {
  background: #fc6e51;
}

.noMargin {
  margin: 0;
}

.orderInfo {
  padding: 0 20px;
  position: relative;
}

.OrderInfoBtnGroup {
  position: absolute;
  right: 0;
  bottom: 15px;
}

.hoverPoint {
  cursor: pointer;
}

.hoverPoint {
  cursor: pointer;
}

.fullSpan {
  width: 100%;
  height: 100%;
}

.tagInput {
  width: 100%;
}

.redButton {
  padding: 5px 10px;
  background: #da4453;
  border: 2px solid transparent;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 5px;
}

.redButton.noMargin {
  margin: 0;
}

.redButton:focus,
.redButton:active {
  outline: none;
}

.redButton:hover {
  background: #ed5565;
  color: #ffffff;
}

.darkGreenButton {
  padding: 5px 10px;
  background: darkgreen;
  border: 2px solid transparent;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 5px;
}

.darkGreenButton.noMargin {
  margin: 0;
}

.darkGreenButton:focus,
.redButton:active {
  outline: none;
}

.darkGreenButton:hover {
  background: #414141;
  color: #ffffff;
}

.yellowButtonTransparent {
  padding: 5px 10px;
  border: 2px solid transparent;
  border-color: #ffd33d;
  border-radius: 3px;
  color: #414141;
  font-weight: 500;
  margin: 0 5px;
}

.yellowButtonTransparent:hover {
  background-color: #414141;
  border: 2px solid #414141;
  color: #ffd33d;
}

.yellowButton {
  padding: 5px 10px;
  background: #ffd33d;
  border: 2px solid transparent;
  border-radius: 3px;
  color: #414141;
  font-weight: 500;
  margin: 0 5px;
}

.yellowButton.noMargin {
  margin: 0;
}

.yellowButton:focus,
.yellowButton:active {
  outline: none;
}

.yellowButton:hover {
  background: #414141;
  color: #ffd33d;
}

.greyButton {
  background: #414141;
  color: #ffffff;
}

.greyButton:hover {
  background: #ffd33d;
  color: #414141;
}

.greenCheckLabel {
  width: 34px;
  padding: 5px 10px;
  background: greenyellow;
  border: none;
  border-radius: 3px;
  color: #414141;
  font-weight: 500;
  margin: 0;
  display: inline-block;
}

/** availability css **/

.availabilityCard {
  min-height: 150px;
  background: white;
  border-radius: 5px;
  padding: 1% 0;
}

.avail-dateCard {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.avail-dateCard p {
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 500;
  background: #da4453;
  color: white;
}

.avail-dateCard table {
  text-align: center;
  width: 100%;
}

.avail-dateCard table td {
  width: 50%;
}

/** availability css (END) **/

/** date blocker css **/

.date-blocker {
  background: white;
  min-height: 300px;
  border-radius: 5px;
  padding: 5% 2%;
}

.date-blocker-info {
  padding: 10px;
}

.date-blocker-info span {
  margin-top: 10px;
}

.date-blocker-table {
  border-spacing: 10px;
  border-collapse: separate;
  margin-top: 20px;
  width: 100%;
}

.date-blocker-table input:active,
.date-blocker-table input:focus {
  outline: none;
}

.date-blocker-table input {
  width: 30px;
  border: 1px solid #ddd;
}

.date-blocker-table button {
  font-size: 10px;
}

.date-blocker-table th,
.date-blocker-table td {
  width: 25%;
  text-align: center;
}

.date-blocker-button {
  font-size: 19px;
  margin: 0 10px;
  color: white;
  background: #ed5565;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-weight: 300;
}

.date-blocker-button:focus,
.date-blocker-button:active {
  outline: none;
}

.date-blocker-button:hover {
  background: #da4453;
}

.date-blocker-updateBtn {
  margin-top: 20px;
}

/** date blocker css (END) **/

/** OrderDetail->Manifest css **/

.manifest {
  padding: 20px 0;
}

.manifest label {
  display: inline;
  font-weight: bold;
  color: Black;
}

.manifest hr {
  border-top: #414141 1px solid;
  margin-bottom: 30px;
}

.manifestTable {
  width: 100%;
  padding: 0 20px;
  border-top: 4px solid #414141;
  border-spacing: 0 10px;
  border-collapse: separate;
  text-align: left;
  padding-top: 15px;
}

.manifestTable tr {
  height: 30px;
}

.manifestTable td {
  border-bottom: 1px solid #414141;
  vertical-align: top;
}

.manifest-btn {
  background: none;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-weight: 500;
  margin-left: 5px;
}

.manifest-btn:focus,
.manifest-btn:active {
  outline: none;
}

.manifestType h1 {
  font-weight: 900;
  margin-top: 10px;
}

.manifest-note {
  min-height: 200px;
}

.manifest-notes {
  min-height: 150px;
  text-align: left;
}

.manifest-notes textarea {
  resize: none;
}

.manifest-notes textarea:focus {
  outline: none;
}

.manifest-custom-task {
  min-height: 150px;
}

.manifest-footer {
  text-align: center;
}

.manifest-barcode-table {
  width: 80%;
}

.manifest-barcode-table * {
  border: 1px solid black;
}

.manifest-barcode-table td {
  border-bottom: none;
  text-align: center;
  padding: 5px 0;
}

/* OrderDetail->Manifest css (END) */

.breakWord {
  display: inline;
  overflow-wrap: break-word;
}

.rowGap {
  height: 30px;
  width: 100vw;
  display: block;
}

/* order edit css */

.orderEdit {
  padding-bottom: 5%;
  position: relative;
}

.OrderEditItems {
  min-height: 325px;
}

.OrderEditItems label {
  font-weight: 300;
  font-size: 15px;
  margin-top: 12px;
}

.plus-minus-button {
  display: inline;
}

.plus-minus-button input {
  width: 50px;
  border: none;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  -webkit-appearance: none;
  margin: 0;
  background: transparent;
}

.plus-minus-button input:focus {
  outline: none;
}

.plus-minus-button button {
  border: none;
  border-radius: 100%;
  background: #ddd;
  padding: 3px 7px;
}

.plus-minus-button button:hover {
  background: #ffd33d;
}

.plus-minus-button {
  margin-top: 10px;
}

.plus-minus-button .fa-plus {
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: 0.2s ease-in-out;
}

.plus-minus-button .disabled:hover {
  background: #ddd;
}

.orderEdit-deleteItemName {
  color: #414141;
  position: relative;
  background: linear-gradient(to bottom, #fbfbfb, #f0f0f0);
  padding: 5px 10px;
  border-radius: 5px;
  border-bottom: 1px solid #aaa;
  border-top: 1px solid white;
  margin-top: 10px;
  width: 100%;
}

.orderEdit-deleteItemName:hover {
  background: linear-gradient(to bottom, #f0f0f0, #ddd);
}

.orderEdit-TrashCanItem p {
  color: #ed5565;
}

.orderEdit-TrashCanItem span {
  background: #4a89dc;
  color: white;
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.orderEdit-TrashCanItem span:hover {
  cursor: pointer;
  text-decoration: none;
  background: #3170c3;
}

.summaryItem {
  margin-top: 10px;
  padding-bottom: 15px;
}

.orderEditSaveBtn {
  width: 50%;
  border: none;
  border-radius: 5px;
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
}

.orderEdit-deleteItem:hover {
  cursor: pointer;
}

.orderEdit-deleteItemDropdown {
  background: #fafafa;
  transition: 0.3s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;
}

.OrderEdit-ItemSummary {
  position: fixed;
  top: 190px;
}

.orderEdit-summaryItem span {
  font-weight: 500;
  font-size: 18px;
}

.orderEdit-returnTypeRow {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  min-height: 50px;
}

.orderEdit-returnTypeText {
  flex: 0.6;
  vertical-align: middle;
}

.orderEdit-returnTypeButtons {
  flex: 0.4;
  display: flex;
  justify-content: flex-end;
}

.orderEdit-confirmationModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 41, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.orderEdit-confirmationModal > div {
  height: 40vh;
  width: 50vw;
  min-width: 300px;
  border: #404041 solid 1px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.orderEdit-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

/* order edit css (END) */

/* react-datepicker css */

.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker-popper {
  max-width: 200px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.datePicker {
  width: 105px;
  padding: 5px 10px;
  border-radius: 2px;
  border: #ccc 1px solid;
}

.datePicker:focus {
  outline: none;
}

.createOrder-datePicker {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 4%;
}

.createOrder-datePicker:focus,
.createOrder-datePicker:active {
  outline: none;
}

/* react-datepicker css (END) */

.noBulletList {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.noBulletList p {
  display: inline-block;
  margin-left: 10px;
}

/* Appointment Flow */

.flowLine {
  position: relative;
  display: flex;
  vertical-align: middle;
}

.flowLine.faded {
  opacity: 0.5;
}

.circle {
  border: 1px solid lightgrey;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.circle.grey {
  background-color: lightgrey;
}

.circle.yellow {
  background-color: #ffd33d;
}

.circle.white {
  background-color: #ffffff;
}

.circle.red {
  background-color: #da4453;
}

.circle.faded {
  opacity: 0.5;
}

.circle.line::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 9px;
  width: 2px;
  height: 30px;
  background-color: lightgrey;
}

.form-control.danger {
  border-color: #da4453;
}

.form-control.danger:focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(218, 68, 83, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(218, 68, 83, 0.6);
}

/* Appointment Badge Colors */

.badge.dropoff {
  background-color: plum;
  color: #ffffff;
}

.badge.pickup {
  background-color: slateblue;
  color: #ffffff;
}

.badge.immediatepickup {
  background-color: turquoise;
  color: #ffffff;
}

.badge.return {
  background-color: gold;
  color: #ffffff;
}

.badge.retreive {
  background-color: orange;
  color: #ffffff;
}

.badge.immediateretrieve {
  background-color: salmon;
  color: #ffffff;
}

.badge.custom {
  background-color: red;
  color: #ffffff;
}

.badge.yellow {
  background-color: #ffd33d;
  color: #ffffff;
}

.badge.lightGreen {
  background-color: lightgreen;
  color: #ffffff;
}

.badge.red {
  background-color: #da4453;
  color: #ffffff;
}

/* route status colors */

.txt.yellow {
  color: #ffc400;
}

.txt.lightGreen {
  color: #0bd800;
}

.txt.red {
  color: #ff3a52;
}

/* direct style class css */

.marginTop10 {
  margin-top: 10px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.marginBottom30 {
  margin-bottom: 30px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop30 {
  margin-top: 30px !important;
}

.marginBottom20 {
  margin-bottom: 20px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginTop15 {
  margin-top: 15px !important;
}

.textColorGrey *,
.textColorGrey {
  color: #aaa;
}

.textCrossedOut,
.textCrossedOut * {
  text-decoration: line-through;
}

.hoverPointer:hover {
  cursor: pointer;
}

/* direct style class css (END) */

/* customer detail */

.customerDetail-address {
  padding: 10px 0;
  line-height: 25px;
}

.customerDetail-address p {
  margin: 0;
}

.customerDetail-addressBtns {
  padding: 25px 20px;
}

.marginThirty {
  margin-top: 30px;
}

.marginForty {
  margin-top: 40px;
}

/* Appointment Details (START) */

.editButtonGroup {
  min-width: 60px;
  width: 60px;
}

.completedTask {
  opacity: 0.5;
}

.taskButtonWidth {
  min-width: 63px;
  width: 63px;
}

.taskCell {
  min-height: 69.5px;
}

/* Appointment Details (END) */

/* Order Detail */

.OrderDetailAppointment p {
  margin: 0;
}

.lightGrey {
  color: lightgrey;
}

/* Order Detail (END) */

/* Appointment Edit */

.appointmentEdit-datePicker {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  padding: 6px 12px;
}

/* Appointment Edit (END) */

/* Action Output (START) */

pre {
  outline: none;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: #ffffff;
  overflow-wrap: break-word;
  width: 100%;
  white-space: pre-wrap;
}

/* Action Output (END) */

/* inventory dropdown */

.inventorySummaryDropdownBtn {
  color: #414141;
  position: relative;
  background: linear-gradient(to bottom, #fbfbfb, #f0f0f0);
  padding: 5px 10px;
  border-radius: 5px;
  border-bottom: 1px solid #aaa;
  border-top: 1px solid white;
  margin-top: 10px;
  width: 100%;
}

.inventorySummaryDropdownBtn:hover {
  background: linear-gradient(to bottom, #f0f0f0, #ddd);
  cursor: pointer;
}

.inventorySummaryDropdownContent {
  background: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  text-transform: capitalize;
  font-weight: 500;
}

.inventorySummaryDropdownBtn * {
  user-select: none;
}

/*inventory dropdown (END)*/

/* scan code  */

.scanInputField {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.scanInputFieldFixed {
  position: fixed;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.barcodeLookup-flexLines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 340px;
}

.barcodeVAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.barcodeVAlign b {
  display: flex;
  flex-direction: row-reverse;
}

.barcodeLookup-flexLines h2 {
  max-width: 40%;
}

.barcodeManualInput {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 6px 10px;
  width: 160px;
}

.barcodeItemPrefix {
  width: 45px;
}

.barcodeItem {
  width: 70px;
}

.barcodeLocationPrefix {
  width: 60px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.barcodeLocation {
  width: 120px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.inputContainer {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.barcodeSearchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 1px;
  color: black;
  background-color: #fcc522;
  border-color: black;
}

.barcodeLookup-flexLines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 340px;
}

.barcodeVAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.barcodeVAlign b {
  display: flex;
  flex-direction: row-reverse;
}

.barcodeLookup-flexLines h2 {
  max-width: 40%;
}

.barcodeManualInput {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 6px 10px;
  width: 160px;
}

.barcodeItemPrefix {
  width: 45px;
}

.barcodeItem {
  width: 70px;
}

.barcodeLocationPrefix {
  width: 60px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.barcodeLocation {
  width: 120px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.inputContainer {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.barcodeSearchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 1px;
  color: black;
  background-color: #fcc522;
  border-color: black;
}

.ScanItemInputToggle {
  display: block;
  margin-bottom: 30px;
}

.ScanItemSearchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 1px;
  box-sizing: content-box;
  color: black;
  background-color: #fcc522;
  border-color: black;
}

.ScanItemManualInput {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 6px 10px;
  width: 160px;
}

.ScanItem-ItemPrefix {
  width: 80px;
}

.ScanItem-Item {
  width: 70px;
}

.ScanItemLocationPrefix {
  width: 60px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ScanItemLocation {
  width: 120px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.ScanIndicator {
  width: 100%;
}

.ScanNowTag,
.ScanNowTag:before,
.ScanNowTag:after {
  background: #333;
  animation: threeBarLoad 1s infinite ease-in-out;
  width: 3px;
  height: 10px;
}

.ScanNowTag {
  position: relative;
  margin: 20px auto;
  animation-delay: -0.3s;
  padding-bottom: 20px;
}

.ScanNowTag:before,
.ScanNowTag:after {
  position: absolute;
  content: "";
}

.ScanNowTag:before {
  left: -10px;
  animation-delay: -0.5s;
}

.ScanNowTag:after {
  right: -10px;
  animation-delay: -0.1s;
}

@keyframes threeBarLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 15px;
  }
  40% {
    box-shadow: 0 -15px;
    height: 20px;
  }
}

.scanDeleteBtn {
  background: none;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 50%;
  padding: 5px 10px;
}

.scanDeleteBtn:hover {
  border: 1px solid #ed5565;
}

.barcode-location-list {
  font-size: 18px;
  margin-top: 20px;
  list-style-type: none;
  padding-left: 0;
}

.barcode-location-list {
  margin: 20px auto;
}

.barcode-location-list tr {
  border-bottom: 1px solid #414141;
  text-align: left;
}

.barcode-location-list td {
  padding: 5px 20px;
}

/* scan code (END)*/

/* barcode mapping */

.barcodeMapping-table {
  width: 100%;
  margin-top: 20px;
}

.barcodeMapping-table tr {
  border-bottom: 1px solid #eee;
}

.barcodeMapping-table td {
  width: 25%;
  font-size: 16px;
}

.barcodeMapping-table th {
  font-size: 18px;
}

.barcodeMapping-create input:focus {
  outline: none;
}

/* barcode mapping(END) */

/* Appointment Planner */

.google-map {
  position: relative;
}

.AppointmentPlannerCard {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 270px;
  background: white;
}

.AppointmentPlannerCard-address-select {
  padding: 5px;
  user-select: none;
}

.AppointmentPlannerCard-address-select:hover {
  cursor: pointer;
  background: #eee;
}

.AppointmentPlannerCard-address-dropdown {
  max-height: 0px;
  overflow: hidden;
  transition: ease-in-out 0.2s;
  padding-left: 20px;
}

.AppointmentPlannerCard-address-dropdown p,
.AppointmentPlannerCard-address-dropdown p span {
  font-size: 12px;
}

.AppointmentPlanner-colorLegend {
  position: absolute;
  top: 10px;
  left: 300px;
  width: 350px;
  background: white;
  padding: 5px 0;
}

/* Appointment Planner (END) */

/* Assign Location */

.assign-location-table {
  width: 100%;
  text-align: center;
}

.assign-location-table tr {
  border-bottom: 1px solid #ddd;
}

.assign-location-table td:last-child {
  border-right: none;
  width: 20%;
}

.assign-location-table td {
  padding: 5px;
  width: 40%;
  border-right: 1px solid #ddd;
}

.assign-location-flexLines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 340px;
}

.assign-location-flexLines h2 {
  max-width: 40%;
}

.assign-location-VAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.assign-location-VAlign div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.assign-location-body {
  min-width: 340px;
}

.assign-location-ManualInput {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 6px 10px;
  width: 160px;
}

.assign-location-ItemPrefix {
  width: 45px;
}

.assign-location-Item {
  width: 70px;
}

.assign-location-LocationPrefix {
  width: 60px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.assign-location-Location {
  width: 120px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.assign-location-SearchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 1px;
  box-sizing: content-box;
  width: 90px;
  color: black;
  background-color: #fcc522;
  border-color: black;
}

.assign-location-formContainer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  min-width: 370px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.assign-location-formContainer div {
  margin-bottom: 35px;
  margin-right: 20px;
}

.assign-location-formContainer form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.assign-location-flexLines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 340px;
}

.assign-location-flexLines h2 {
  max-width: 40%;
}

.assign-location-VAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.assign-location-VAlign div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.assign-location-body {
  min-width: 340px;
}

.assign-location-ManualInput {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 6px 10px;
  width: 160px;
}

.assign-location-ItemPrefix {
  width: 45px;
}

.assign-location-Item {
  width: 70px;
}

.assign-location-LocationPrefix {
  width: 60px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.assign-location-Location {
  width: 120px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.assign-location-SearchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 1px;
  box-sizing: content-box;
  width: 90px;
  color: black;
  background-color: #fcc522;
  border-color: black;
}

.assign-location-formContainer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  min-width: 370px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.assign-location-formContainer div {
  margin-bottom: 35px;
  margin-right: 20px;
}

.assign-location-formContainer form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Assign Location (END) */

/* Order Create Type Card */

.order-create-type-card {
  margin-top: 50px;
  padding: 50px 0;
  border: 1px solid #414141;
  border-radius: 10px;
  transition: 0.1s ease-in;
}

.order-create-type-card h2 {
  font-weight: 500;
}

.order-create-type-card:hover {
  background: #414141;
  cursor: pointer;
}

.order-create-type-card:hover h2 {
  color: #ffd33d;
}

/* Order Create Type Card (END) */

/* Order Create Space Plan */

.space-plan-item-row {
  border-bottom: 1px solid #eee;
  padding: 5px 0px;
}

.progress-bar-space {
  background-color: #b383ea;
}

.progress-striped .progress-bar-space {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.scanIndicator {
  position: relative;
  width: 100%;
  height: 100px;
}

.scanIndicator > div {
  width: 100px;
}

.scanIndicator > div {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000000;
  opacity: 0;
  animation: none;
}

.scanIndicator.scanning > div {
  animation: signalRipple forwards infinite ease-out;
  animation-duration: 2s;
}

@keyframes signalRipple {
  0% {
    width: 2px;
    height: 2px;
    top: calc(50% - 1px);
    left: calc(50% - 1px);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    opacity: 0;
  }
}

.scanIndicator > i {
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 22px);
  opacity: 0;
  animation: none;
}

.scanIndicator.scanning > i {
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 22px);
  opacity: 0;
  animation: qrFade forwards infinite ease;
  animation-duration: 2s;
}

@keyframes qrFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scanInput button.btn {
  position: absolute;
  background-color: rgb(72, 207, 173);
  width: 130px;
  color: #ffffff;
  top: calc(50% - 15px);
  left: calc(50% - 65px);
  font-size: 14px;
  font-weight: 500;
}

.manualInput {
  margin: 13px 0;
}

.manualInput > input.form-control,
.manualInput > button.btn,
.manualInput > p {
  display: inline-block;
}

.manualInput > input.form-control:nth-child(1) {
  width: 100px;
}

.manualInput > input.form-control:nth-child(3) {
  width: 60px;
}

.manualInput > button.btn {
  width: 40px;
  margin-left: 15px;
}

.manualInput > button.btn.enterButton {
  display: block;
  width: 100px;
  margin: auto;
  margin-top: 15px;
}


.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.ant-editable-field:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-tabs-tab .anticon-delete {
  margin-right: 0px;
}
