$purple: #ac92ec;
$mint: #37bc9b;
$skyBlue: #3bafda;
$oceanBlue: #4a89dc;
$pink: #ffc0cb;

.order-appointment-status-tag {
  font-size: 11px;
  background: none;
  color: $grey;
  padding: 3px 20px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid $grey-light;

  &.red {
    color: white;
    background: $red;
  }

  &.blue {
    color: white;
    background: $blue;
  }

  &.orange {
    color: white;
    background: $orange;
  }

  &.green {
    color: white;
    background: $green;
  }

  &.purple {
    color: white;
    background: $purple;
  }

  &.mint {
    color: white;
    background: $mint;
  }

  &.skyBlue {
    color: white;
    background: $skyBlue;
  }

  &.oceanBlue {
    color: white;
    background: $oceanBlue;
  }

  &.pink {
    color: white;
    background: $pink;
  }
}
