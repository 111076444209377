.organization-info-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  .org-index-title-with-edit {
    font-size: 25px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-wrapper {
    padding: 20px 0;
    // display: grid;
    // grid-template-columns: auto 1fr 1fr;
    display: flex;
    border: 1px solid $grey-light;

    .logo-wrap {
      padding: 0 20px;
      width: 200px;
      border-right: 1px solid $grey-light;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 150px;
      }

      .no-logo {
        font-size: 50px;
        color: $grey;
      }
    }

    .info-wrap {
      width: 100%;
      display: flex;
      padding: 20px;
      justify-content: space-evenly;
      flex-direction: column;

      .info-row {
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .label-value {
          flex: 1;
          margin: 10px;

          .field-label {
            color: $grey;
            font-size: 12px;
          }
          .field-value {
            margin-top: 5px;
            white-space: pre-line;
          }
        }

        @include md {
          flex-direction: column;

          .label-value {
            flex: unset;
          }
        }
      }

      &.packing-notes {
        border-left: 1px solid $grey-light;
        max-height: 250px;
        word-break: break-word;
        overflow: auto;
      }
    }
  }
}
