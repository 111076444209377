.summary-card {
  width: 100%;
  @include box-shadow();
  padding: 20px;
  overflow-y: auto;
  height: 80vh;
  min-height: 200px;
  @include scrollbar();
  @include md {
    height: auto;
  }

  .card-loading {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .summary-table {
    table {
      width: 100%;
      margin-top: 10px;

      th {
        font-weight: 300;
        font-size: 13px;
        color: $grey;
        padding: 10px 0;
        border-bottom: 1px solid darken($grey-light, 5%);
      }

      .no-data {
        text-align: center;
        padding-top: 20px;

        p {
          font-size: 12px;
          color: $grey;
        }
      }

      tr {
        height: 50px;
        vertical-align: middle;
        td {
          &.name {
            div {
              &:nth-child(2) {
                font-size: 12px;
                color: $grey;
              }
            }
          }
        }
      }
    }
  }

  .subtotal {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 5px;
    color: $grey;
    border-bottom: 1px solid darken($grey-light, 5%);
  }

  .coupon {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .yellow-button {
      border: none;
      background: $yellow;
      color: $charcoal;
      margin-left: 10px;

      &:hover {
        background: $charcoal;
        color: $yellow;
      }
    }

    .coupon-control {
      button {
        margin-left: 10px;
      }
    }
  }

  .price-breakdown {
    margin-top: 20px;

    .subtotal,
    .coupon,
    .tax,
    .total,
    .monthly-recurring {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $grey;
      border-bottom: none;
      padding: 0;
      margin: 0;
    }

    .total {
      margin-top: 10px;
      color: $green;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .submit-btn {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
