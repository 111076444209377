.dot-button-dropdown {
  position: relative;

  .dropdown-list {
    position: absolute;
    top: 10;
    right: 0px;
    background: white;
    @include box-shadow();
    @include border-radius();
    transition: max-height 0.2s ease-in;
    max-height: 100px;
    overflow: hidden;

    &.collapsed {
      max-height: 0px;
    }

    .dropdown-row {
      padding: 5px 10px;
      text-transform: capitalize;

      &:hover {
        background: $grey-light;
        cursor: pointer;
      }

      &:first-child() {
        color: $blue;
      }

      &:last-child() {
        color: $red;
      }
    }
  }
}
