.common-go-back-button {
  span {
    color: $charcoal;
    cursor: pointer;
    transition: color 300ms;
    &:hover {
      color: $blue;
    }
  }
}
