.new-appointment-modal {
  display: flex;
  flex-direction: column;

  label {
    display: block;
    color: $charcoal;
    font-weight: 300;
    margin-top: 10px;

    &::after {
      content: "*";
      color: $red;
      margin-left: 2px;
    }
  }

  .new-appointment-dropdown {
    width: 200px;
    text-transform: capitalize;
  }

  .modal-row {
    display: flex;
    justify-content: space-between;
  }
}
