.input-with-dropdown {
  $input-height: 32px;

  > div {
    position: relative;
  }

  .error {
    color: $red;
    font-size: 10px;
  }

  .spinner {
    box-sizing: content-box;
    height: $input-height;
    width: $input-height;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 2px;
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 99%;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    box-sizing: border-box;
    background-color: $white;
    @include box-shadow();

    z-index: 2;
  }

  .search-option {
    padding: 8px 20px;
    border-bottom: thin solid $grey-light;
    transition: all 0.25s;
    cursor: pointer;

    &:hover {
      background-color: $grey-light;
    }
    p {
      margin: 0;
    }
  }
}
