.back-button {
  align-items: center;
  color: $grey;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  img {
    margin-right: 5px;
  }

  p {
    margin: 0;
  }

  img,
  p {
    display: inline-block;
  }
}
