.customer-info-card {
  width: 100%;
  border: 1px solid $green;
  border-top: 10px solid $green;

  .header {
    padding-bottom: 10px;
    border-bottom: 1px solid $grey-light;
    .customer-name {
      font-size: 23px;
    }
  }

  .customer-info-details {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @include md {
      flex-direction: column;
      justify-content: flex-start;
    }

    .left,
    .right {
      flex: 1;

      @include md {
        text-align: center;
      }
    }

    .left {
      border-right: 1px solid $grey-light;

      @include md {
        border-right: none;
      }
    }

    .right {
      padding: 10px 20px;
    }

    .customer-phone,
    .customer-address {
      margin-top: 10px;
    }

    .customer-address {
      .appointment-address {
        color: $grey;
      }

      .address-select-title {
        color: $grey;
        font-size: 15px;
        margin-bottom: 5px;
      }

      .address-select {
        display: flex;
        button {
          margin-left: 10px;
        }
      }

      .address-confirm-buttons {
        margin-top: 10px;
        display: flex;
        button {
          margin-right: 10px;
        }
      }
    }

    .appointment-date-time {
      margin-top: 20px;

      .appointment-date,
      .appointment-time {
        span {
          color: $grey;
        }
      }

      .time-select {
        margin-left: 10px;
        min-width: 124px;
      }

      .date-confirm-buttons {
        margin-top: 10px;
        button {
          margin-right: 10px;
        }
      }

      .appointment-service-area,
      .appointment-customer-segment {
        margin: 10px 0;
      }
    }
  }
}
