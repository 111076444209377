.appointment-assign-qr-modal {
  p {
    margin: 0;
  }

  .scan-notifier {
    align-items: center;

    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(64, 64, 65, 0.4);
      }
      70% {
        -webkit-box-shadow: 0 0 0 10px rgba(64, 64, 65, 0);
      }
      100% {
        -webkit-box-shadow: 0 0 0 0 rgba(64, 64, 65, 0);
      }
    }
    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 rgba(64, 64, 65, 0.4);
        box-shadow: 0 0 0 0 rgba(64, 64, 65, 0.4);
      }
      70% {
        -moz-box-shadow: 0 0 0 10px rgba(64, 64, 65, 0);
        box-shadow: 0 0 0 10px rgba(64, 64, 65, 0);
      }
      100% {
        -moz-box-shadow: 0 0 0 0 rgba(64, 64, 65, 0);
        box-shadow: 0 0 0 0 rgba(64, 64, 65, 0);
      }
    }

    div {
      padding: 20px;
      border: 2px solid lighten($charcoal, 25%);
      border-radius: 999px;
      margin: 15px 0;
      animation: pulse 2s infinite;
    }
  }

  .modal-footer {
    align-items: center;
    border: none;

    .assign-qr-single-item-input {
      align-items: flex-start;
      margin: 3px 0;

      label {
        color: transparent;
        font-size: 11px;
        font-weight: 400;
        height: 18px;
        padding: 1px 7px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin: 0;
        transition: all 250ms;

        &.selected {
          color: $charcoal;
          background-color: $yellow;
        }

        &.successful {
          color: $white;
          background-color: $green;
          font-weight: 200;
        }

        &.errored {
          color: $white;
          background-color: $red;
          font-weight: 200;
        }
      }

      .flex-row {
        align-items: flex-end;

        span {
          font-size: 11px;
          color: $red;
          padding-left: 7px;
        }
      }

      .inl-flex-row {
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border: 1px solid $grey-light;
        @include border-radius();
        transition: all 250ms;

        .ant-alert {
          width: 100%;
        }

        &.selected {
          border-top-left-radius: 0;
          border: 1px solid $yellow;
        }

        &.successful {
          border-top-left-radius: 0;
          border: 1px solid $green;
        }

        &.errored {
          border-top-left-radius: 0;
          border: 1px solid $red;
        }
      }

      .item-name {
        width: 100px;
        text-overflow: ellipsis;
        text-align: left;
      }

      input {
        text-align: center;
        padding: 3px 0;
        border: 1px solid lighten($grey, 20%);
        @include border-radius();
        margin: 0 5px;

        &::placeholder {
          color: lighten($grey, 10%);
          font-style: italic;
          font-weight: 100;
        }

        &:disabled {
          background-color: transparent;
        }
      }

      .assign-qr-user-code {
        width: 85px;
      }

      .assign-qr-item-code {
        width: 60px;
      }
    }

    .ant-btn {
      margin-top: 20px;
    }
  }
}
