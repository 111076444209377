// Border-radius
$base-radius: 4px;
$radius-none: 0px;
$radius-medium: 6px;
$radius-big: 15px;
$radius-round: 100%;
@mixin border-radius($radius: $base-radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Animation
@mixin animation($args) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -o-animation: $args;
  animation: $args;
}

// Keyframes
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Transition
@mixin transition($args...) {
  transition: $args;
  -webkit-transition: $args;
  -moz-transition: $args;
}

// Transform
@mixin transform($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  transform: $args;
}

// Box-shadow
$base-box-shadow-top: 0;
$base-box-shadow-left: 2px;
$base-box-shadow-blur: 4px;
$base-box-shadow-color: rgba(black, 0.3);
@mixin box-shadow(
  $color: $base-box-shadow-color,
  $top: $base-box-shadow-top,
  $left: $base-box-shadow-left,
  $blur: $base-box-shadow-blur
) {
  -webkit-box-shadow: $top $left $blur $color;
  -moz-box-shadow: $top $left $blur $color;
  box-shadow: $top $left $blur $color;
}

// Breakpoints
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-desktop-large: 1200px;
@mixin sm {
  @media screen and (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: #{$breakpoint-desktop-large}) {
    @content;
  }
}

$base-scrollbar-track-color: $grey-light;
$base-scrollbar-thumb-color: $grey;
$base-scrollbar-border-radius: $radius-big;
$base-scrollbar-thickness: 10px;
@mixin scrollbar(
  $border-radius: $base-scrollbar-border-radius,
  $thickness: $base-scrollbar-thickness,
  $thumb-color: $base-scrollbar-thumb-color,
  $track-color: $base-scrollbar-track-color
) {
  &::-webkit-scrollbar {
    display: block;
    width: $thickness;
    height: $thickness;
  }
  &::-webkit-scrollbar-track {
    background: $track-color;
    @include border-radius($border-radius);
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    @include border-radius($border-radius);
  }
}
