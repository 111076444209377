.ant-select-dropdown-menu-item {
  text-overflow: initial;
}
.ant-menu-inline {
  border: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-scroll-number .current {
  color: white;
}
