.add-order-items {
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    text-align: center;
    color: $grey;
  }
  table {
    width: 100%;
    margin-top: 20px;

    th,
    td {
      border: 1px solid $grey-light;
      width: 25%;
      padding: 10px;

      .search-box {
        position: relative;

        input {
          border: none;
          width: 100%;
          padding: 0;
          border-radius: 0;
        }

        .fix-right-loading {
          position: absolute;
          right: 0;
          top: 0;
        }

        .dropdown-box {
          position: absolute;
          @include box-shadow();
          top: 38px;
          left: -10px;
          right: -10px;
          background: white;
          z-index: 2;

          span {
            display: block;
            border-bottom: 1px solid $grey-light;
            padding-bottom: 10px;
            color: $grey;
            padding: 10px;
            @include transition(all 0.2s ease);

            &:hover {
              cursor: pointer;
              background: $grey-light;
            }
          }
        }
      }

      &:nth-child(odd) {
        text-align: right;
      }

      button {
        border: none;
        color: $blue;
      }
    }

    th {
      font-weight: 500;
      color: $grey;
    }
  }
}
