.plus-minus-input {
  width: 80px;
  height: calc(80px / 3);
  display: flex;
  flex: row;
  justify-content: center;
  @include box-shadow();

  .plus,
  .minus {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plus {
    background: $yellow;
    cursor: pointer;
  }

  .minus {
    cursor: pointer;
  }

  .quantity {
    width: 40%;
    border: none;
    border-left: 1px solid $grey-light;
    text-align: center;

    &:focus {
      outline: 0;
    }
  }
}
