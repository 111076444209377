.view-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: $grey;

  .box-view-icon,
  .list-view-icon {
    margin-right: 5px;

    &.selected {
      color: $blue;
    }
  }
}
