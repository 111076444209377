#control-center-page {
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .download-otif-wrapper {
    display: flex;
    justify-content: flex-end;

    .download-otif-button {
      margin: 0 0 0 16px;
    }
  }

  h1 {
    margin: 0 24px 0 0;

    @media screen and (max-width: 560px) {
      text-align: center;
    }
  }

  .control-center-header {
    display: flex;
    margin: 24px 0;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 560px) {
      justify-content: center;
    }

    .left {
      display: flex;
      flex-wrap: wrap;
      .date-controller {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .date-display {
          margin: 0 5px;
          font-size: 20px;
        }
      }

      .appointment-summary {
        @media screen and (max-width: 560px) {
          display: none;
        }
      }
    }

    .right {
      @media screen and (max-width: 560px) {
        display: none;
      }
    }
  }

  .control-center-appts {
    border-top: 2px solid #888;
    margin-top: 30px;
    padding-top: 20px;

    .control-center-slot {
      margin: 10px 0;
    }

    .control-center-slot-tag {
      width: 100%;
      background: #fbfbfb;
      border: 1px solid #ddd;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      label {
        font-size: 25px;
        color: #414141;
        font-weight: 300;

        > span {
          font-size: 13px;
          color: #aaa;
        }
      }
    }

    .control-center-slot-appt {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 200px;
      padding: 20px 0;

      border-bottom: 1px solid $grey-light;

      &:last-child() {
        border-bottom: none;
      }

      .control-center-appt-left {
        display: flex;
        flex-direction: column;
        width: 300px;
        margin-right: 50px;

        > .type-status {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;

          p {
            font-size: 20px;
            border-bottom: 1px solid;
            border-bottom-color: transparent;
          }
        }

        .customer-segment {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: capitalize;
          margin-top: 10px;
          padding: 2px 5px;
          width: 100px;
          background: $charcoal;
          color: $yellow;
          text-align: center;
          border: 1px solid $yellow;
          @include border-radius();
        }
      }

      .control-center-appt-mid {
        display: flex;
        flex-direction: column;
        width: 300px;

        @media (max-width: 750px) {
          margin-top: 20px;
        }

        .address-block {
          margin: 10px 0;
          font-size: 17px;
          font-weight: 500;
        }
      }

      .control-center-appt-right {
        @media (max-width: 1320px) {
          margin-top: 20px;
          width: 100%;
        }

        .control-center-item-list {
          width: 100%;
          max-height: 150px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: block;
            width: 5px;
            margin-left: 10px;
          }

          &::-webkit-scrollbar-track {
            background: #eee;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 10px;
          }

          .item-row {
            font-size: 12px;
            color: #888;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
  .ant-space-align-center {
    display: flex;
    justify-content: space-between;
  }
}

.control-center-popover-list p {
  font-size: 12px;
  color: #aaa;
  font-weight: 100;
  margin: 0;
}
