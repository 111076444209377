.order-create {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .title {
    font-size: 25px;
  }

  .left-panel {
    flex: 0.6;
    min-width: 400px;
    padding-right: 20px;
    @include md {
      flex-basis: 100%;
      padding-right: 0;
      min-width: 100%;
    }

    .select-service-area,
    .select-address,
    .select-dates,
    .select-products,
    .billing-details,
    .billing-type {
      margin-top: 20px;
      width: 100%;
    }

    .billing-type {
      @include box-shadow();
      padding: 20px;

      &.spinning {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .button-group {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        button {
          margin-right: 10px;
        }
      }
    }
  }

  .right-panel {
    flex: 0.4;
    min-width: 300px;
    @include md {
      flex-basis: 100%;
      min-width: 100%;
    }

    .summary {
      margin-top: 20px;
    }
  }
}
