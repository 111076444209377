.table-pagination-control {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .control-buttons {
    margin-top: 20px;
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
