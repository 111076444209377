.shipments-table {
  margin-top: 20px;

  .billed-icon {
    color: $red;

    &.billed {
      color: $green;
    }
  }
}
