.create-ikea-shipments-modal {
  .in-out-bound-select {
    margin: 20px 0;
  }

  .item-table {
    margin-top: 20px;
  }

  .date-picker {
    margin-top: 20px;
  }
}
