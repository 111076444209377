.active-filter {
  margin-top: 10px;

  span {
    margin-left: 20px;
    border-bottom: 1px solid lighten($charcoal, 30%);
    color: $grey;

    &.active,
    &:hover {
      cursor: pointer;
      color: $blue;
      border-bottom: 1px solid $blue;
    }
  }
}
