.order-status-tag {
  font-size: 11px;
  background: none;
  color: $grey;
  padding: 3px 20px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid $grey-light;

  &.red {
    color: white;
    background: $red;
  }

  &.yellow {
    color: $charcoal;
    background: $yellow;
  }

  &.blue {
    color: white;
    background: $blue;
  }

  &.orange {
    color: white;
    background: $orange;
  }

  &.green {
    color: white;
    background: $green;
  }
}
