.page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#appointment-details-page {
  display: flex;
  flex-wrap: wrap;

  .card {
    @include border-radius();
    padding: 20px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .appointment-items-card {
    width: 100%;
    border: 1px solid $blue;
    border-top: 10px solid $blue;

    &.spinning {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.custom-appointment-alert-message {
  width: 100%;
  margin-top: 20px;
}
