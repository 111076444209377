#fulfillment-order-create-page {
  .label {
    padding-left: 0;
    font-weight: 100;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-button {
      display: flex;
      align-items: center;
      color: $grey;

      img {
        margin-right: 5px;
      }

      &:last-child {
        justify-content: flex-end;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      font-size: 25px;
    }

    .dummy {
      width: 100px;
    }
  }

  .collapse-container {
    margin-top: 20px;
    background: white;
    border: none;
    .collapse-panel-wrap {
      border: 1px solid $grey-light;
      background: white;
      overflow: hidden;
      margin-bottom: 20px;

      .step-header {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        .complete-icon,
        .step-number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          margin-right: 20px;
        }

        .step-number {
          border: 1px solid $grey-mid;
          color: $grey;
        }

        .complete-icon {
          border: 1px solid $green;
          color: $green;
          font-size: 30px;
        }

        .step-title {
          color: $grey;
          font-size: 20px;
          transition: all 0.2s ease-in;
        }
      }

      &.active {
        border: none;
        .step-header {
          border: 1px solid $blue;
        }

        .step-number {
          border: 1px solid $blue;
          color: $blue;
        }

        .step-title {
          color: $blue;
        }
      }

      .step-next-button-wrap {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        .step-next-button,
        .create-order-button {
          border: 1px solid $grey;
          padding: 10px 30px;
          user-select: none;

          &:hover {
            cursor: pointer;
            border-color: $blue;
            color: $blue;
          }

          &.disabled {
            border: 1px solid $grey-light;
            color: $grey-light;
            &:hover {
              cursor: no-drop;
            }
          }
        }
      }

      .loading-button {
        width: 100px;
        height: 50px;
        margin: auto;
        margin-top: 20px;
        font-size: 30px;
        color: $blue;
      }
    }
  }

  .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-content-box {
    padding: 80px 0 40px;
  }

  .ant-collapse-content {
    border: none;
  }
}
