.topBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 2;
  background-color: #fff;
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid #eee;
}

.topBar-container {
  position: relative;
  z-index: 3;
  background: #fff;
}

.topBar .brandLogo {
  display: inline-block;
}

.topBar .brandVersion {
  display: inline-block;
}

@media screen and (max-width: 990px) {
  .topBar .brandVersion {
    display: none;
  }
}

.topBar .navigationMenu {
  border: none;
}

.topBar .navbar-env {
  color: #a9a9a9;
  font-size: 9px;
  display: inline-block;
  margin-left: 5px;
}

.navbar-mobile-container {
  padding: 5px 10px 5px 15px;
  border-bottom: 1px solid #eee;
}

.navbar-mobile-menu {
  transition: max-height 0.3s, top 0.3s;
  position: relative;
  overflow: hidden;
  max-height: 0;
  top: -400px;
}

@media screen and (max-width: 990px) {
  .topBar.opened .topBar-container {
    border-bottom: 1px solid #eee;
  }
  .topBar.opened .navbar-mobile-menu {
    max-height: 350px;
    top: 0;
  }
}

.navbar-account-btn {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #878787;
  text-align: center;
  padding: 0;
  margin-right: 10px;
}

.navbar-account-btn > span {
  font-weight: 300;
}

.ant-dropdown-menu-item > a {
  text-decoration: none;
}

.menu-toggle {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 12px;
  padding: 0 10px;
  margin: -5px 15px 0;
  background: transparent;
  border: none;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #888;
  font-size: 0;
  transition: all 0.25s ease-in-out;
}

@media screen and (min-width: 991px) {
  .menu-toggle {
    display: none;
  }
}

.menu-toggle:before,
.menu-toggle:after {
  content: "";
  display: block;
  width: 70%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.menu-toggle.is-active {
  border-color: transparent;
}

.menu-toggle.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.menu-toggle.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media screen and (max-width: 990px) {
  .menu-toggle + .navbar-account-btn {
    display: none;
  }
  .navbar-mobile-menu .navbar-account-btn {
    display: inline-block;
    margin-right: 0;
  }
}

.logoLink {
  height: 50px;
  width: auto;
  display: inline-block;
  padding: 15px;
  position: relative;
  z-index: 3;
}

.logoLink > img {
  height: 100%;
  width: auto;
}

.header-facility-selector {
  align-items: center;
  margin-right: 15px;

  p {
    margin: 0;
  }

  i {
    font-size: 10px;
    margin-left: 10px;
  }

  .facility-label {
    color: $grey;
    margin: 0 5px 0 0;

    @include sm {
      display: none;
    }
  }

  .ant-btn {
    border-color: $yellow;
    min-width: 140px;
  }
}
