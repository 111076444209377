.colorRow {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.colorRow:hover {
  background-color: #fafafa;
}
.colorRow > div {
  width: 30px;
  height: 30px;
  border-radius: 7px;
}
.colorRow > p {
  margin: 0;
}
