.details-and-notes {
  display: flex;

  .label {
    font-weight: 100;
    padding-left: 0;

    .red {
      color: $red;
    }

    .green {
      color: $green;
    }
  }

  @include lg {
    flex-direction: column;
  }

  .details {
    flex: 1;

    .title {
      color: $charcoal;
      font-size: 20px;
      font-weight: 100;
    }

    .details-content {
      border: 1px solid $grey-light;
      min-height: 200px;
      padding: 10px;

      .label-field {
        .label {
          color: $grey;
          font-size: 12px;
        }
      }

      .address-wrapper {
        padding: 0 0 8px;

        .warning-msg {
          color: $red;
          font-size: 10px;
          margin: 5px 0;
        }
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px;
    width: 100%;

    @media screen and (min-width: 1024px) {
      margin: 0 10px 0 0;
    }

    .details-header {
      height: 32px;
    }

    .details-content {
      display: flex;
      flex: 1;

      .left,
      .mid,
      .right {
        padding: 10px;
        flex: 1;
        overflow: auto;
      }

      .mid,
      .right {
        .label-field {
          &:not(:first-child) {
            margin-top: 16px;
          }
        }
      }

      .left {
        display: flex;
        flex-direction: column;
      }

      .mid {
        border-right: 1px solid $grey-light;
      }

      .right {
        flex: 0.7;
      }

      @include md {
        flex-direction: column;
        height: auto;

        .mid {
          border-right: none;
        }
      }
    }

    .edit-shipping-details-modal-trigger {
      display: inline-block;
      color: $blue;
      cursor: pointer;
      margin: 6px 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .order-notes {
    flex: 0.6;
  }
}

.ant-modal-confirm-title {
  font-weight: 600;
}
.ant-checkbox-wrapper {
  font-weight: normal;
}

#edit-shipping-info .ant-divider-inner-text {
  font-weight: bold;
}
