.update-fulfilled-from {
  .ant-btn {
    margin-left: 50px;
  }
}

.ant-modal {
  .warehouse-select-wrapper {
    display: flex;

    .warehouse-select {
      display: flex;
      flex-direction: column;
      width: 250px;
      padding-right: 20px;
    }

    .select-label {
      font-weight: 300;
      margin: 0 0 5px;
    }

    .warehouse-select-button {
      align-self: flex-end;
    }
  }
}
