.order-edit-items-table {
  margin-bottom: 50px;
  .space-plan-display {
    margin-bottom: 10px;
    .name-wrap {
      display: flex;
      align-items: center;
      .name {
        font-size: 25px;
        margin-right: 10px;
      }

      .edit-button {
        margin-top: 5px;
      }
    }

    .description {
      color: $grey;
      font-size: 12px;
    }
  }

  .order-items-action {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    .order-items-search {
      flex: 1;
      margin-right: 10px;
    }

    .order-item-buttons {
      .add-item-button {
        margin-left: 10px;
      }
    }
  }
}
