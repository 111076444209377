.assign-permissions-form {
  .permissions-checkboxes {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
    border: 1px solid $grey-light;
    border-radius: 4px;
    @include scrollbar();

    .ant-checkbox-wrapper {
      margin: 8px 5px;
    }

    .ant-checkbox-group-item {
      margin: 8px;
      font-weight: 400;
    }
  }

  .check-all-btns {
    margin-bottom: 15px;

    .ant-btn {
      margin-right: 10px;
    }
  }
}
