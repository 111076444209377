#fulfillment-order-details-page {
  .order-header {
    margin-bottom: 20px;

    .header-buttons {
      display: flex;
      justify-content: space-between;
    }

    .shipment-tags {
      color: $grey;

      .ant-tag {
        margin: 20px 0 20px 10px;
      }
    }

    .order-id-with-edit-btn {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .ant-spin {
        margin-left: 90px;
      }

      .foid {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}
