#create-customer-page {
  .warning-bar {
    margin-bottom: 20px;

    .suggestion-title {
      margin-top: 10px;
      font-weight: bold;
    }

    .organization-link {
      color: $blue;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 30px;
    font-weight: 300;
  }

  .label-input {
    margin-top: 20px;
    .label {
      font-size: 13px;
      color: $grey;
      margin: 0;
      padding: 0;
      font-weight: 100;

      &::after {
        content: "*";
        color: $red;
        margin-left: 5px;
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .error-bar {
      margin: 10px;
    }
  }
}
