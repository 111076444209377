.wro-filter {
  margin-top: 30px;
  @include box-shadow();
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .filter-cell {
    padding: 15px 20px;
    flex-grow: 1;
    justify-content: flex-start;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent;
      height: 5px;
      @include transition(all 0.5s ease);
    }
    &.active,
    &:hover {
      cursor: pointer;

      &:before {
        background: $yellow;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid $grey-light;
    }

    span {
      color: $charcoal;
      user-select: none;
    }
  }
}
