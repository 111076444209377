.return-summary-card {
  width: 100%;
  @include box-shadow();
  padding: 20px;
  overflow-y: auto;
  max-height: 80vh;
  min-height: 500px;
  @include scrollbar();
  @include md {
    height: auto;
  }

  .return-summary-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    .table-header,
    .table-row {
      display: flex;
      width: 100%;

      div {
        flex-basis: 50%;
      }
    }

    .table-header {
      color: $grey;
      border-bottom: 1px solid $grey-light;
      padding: 10px 0;
      margin-bottom: 5px;
    }

    .table-row {
      margin-top: 5px;
    }

    .table-footer {
      border-top: 1px solid $grey-light;
      margin-top: 20px;
      padding: 10px 0;

      p {
        color: $green;
        font-size: 13px;
        text-align: right;
      }
    }
  }

  .return-fee {
    width: 100%;
    display: flex;
    flex-direction: column;

    .no-return-fee {
      width: 100%;
      text-align: center;
      color: $grey;
    }

    .return-fee-title {
      width: 100%;
      color: $grey;
      padding-bottom: 5px;
      border-bottom: 1px solid $grey-light;
    }

    .return-fee-reveal {
      background: none;
      border: none;
      text-decoration: underline;
      width: 120px;
      text-align: left;
      color: $blue;

      &:hover {
        color: darken($blue, 10%);
      }
    }

    .return-fee-content {
      margin: 5px 0;
    }

    .notes-input {
      margin-top: 30px;
    }

    .return-buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        margin: 0 5px;
      }
    }
  }

  .empty-row {
    margin-top: 20px;
  }
}
