.closetFilter {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 1em;
}

@media screen and (max-width: 767px) {
  .closetFilter {
    display: block;
    margin: 1em 0;
  }
}

.closetFilter .btn {
  padding: 2px 12px;
  background: transparent;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  color: #404041;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
}

.closetFilter .btn.active {
  box-shadow: none;
  background: #404041;
  color: #fff;
}

.closetFilter .btn:hover {
  color: #404041;
  background: rgba(0, 0, 0, 0.1);
}
