.order-edit-customer-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
  .back-btn {
    display: flex;
    align-items: center;
    color: $blue;

    &:hover {
      cursor: pointer;
    }
  }

  .order-number,
  .customer-info {
    color: $charcoal;
    font-size: 25px;
    margin-top: 20px;
  }

  .customer-info {
    margin-top: 10px;
    .email {
      color: $grey;
      font-size: 15px;
      margin-top: 10px;
    }

    .name {
      color: $blue;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
