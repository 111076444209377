.appointment-tasks-button {
  position: relative;

  .task-counter {
    position: absolute;
    top: -8px;
    right: 3px;
    font-size: 10px;
    background: $red;
    color: white;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.appointment-tasks-drawer {
  margin-top: 20px;
  .tasks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey-light;

    .header-text {
      font-size: 25px;
    }

    .header-buttons {
      button {
        background: none;
        border: none;
        &:hover {
          cursor: pointer;
        }
      }

      .tasks-button {
        &-blue {
          color: $blue;
        }

        &-grey {
          color: $grey;
        }
      }
    }
  }

  .appointment-tasks-list {
    display: flex;
    flex-direction: column;

    .no-data {
      margin-top: 20px;
      padding: 20px;

      .no-data-text {
        color: $grey;
      }
    }

    .appointment-task {
      border-top: 5px solid $grey-light;
      width: calc(100% + 46px);
      padding: 0 24px;
      margin-left: -24px;

      &:not(:first-child) {
        margin-top: 30px;
      }

      .task {
        min-height: 150px;
        white-space: pre-line;
      }

      .action-button {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    &.spinning {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }

  .new-task {
    margin: 20px 0;
  }

  .task-footer {
    border-top: 1px solid $grey-light;
    padding-top: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: $grey;

    .name-time {
      span {
        font-weight: 700;
        color: $charcoal;
      }
    }
  }
}
