#sync-order-with-shopify-page {
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .back-button-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      height: 100%;
    }

    .dummy {
      display: flex;
      flex: 1;
    }

    .title {
      font-size: 25px;
    }
  }

  .continue-button-row {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
