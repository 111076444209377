.search-box {
  input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    padding: 5px 20px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: lighten($charcoal, 50%);
    }
  }
}
