.back-button {
  align-items: center;
  color: $blue;
  cursor: pointer;

  span {
    font-size: 12px;
    margin: 5px;
  }
}
