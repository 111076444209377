.loginContainer {
  background: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginDark {
  background-color: rgba(40, 40, 41, 1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 30px;
  display: flex;
  flex-direction: row;
  height: 350px;
  width: 767px;
  min-width: 270px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .loginDark {
    width: 270px;
  }
}

.loginDark > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginDark h3,
.loginDark p {
  margin-bottom: 20px;
}

.loginBigContent {
  flex: 4;
  padding: 0 35px;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .loginBigContent {
    padding: 0;
  }
}

.loginBigContent .loginForm div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loginBigContent input {
  width: 100%;
  border: none;
  border-bottom: #414141 1px solid;
  border-radius: 0;
  box-shadow: none !important;
  height: 30px;
  margin: 5px 0;
  padding: 0;
  background-color: transparent;
  transition: 0.5s ease;
  margin: 20px 0;
}

.loginBigContent input:focus {
  border-bottom: 1px solid;
  border-color: rgba(65, 65, 65, 0.2);
  outline: none;
  box-shadow: none;
  background-color: transparent !important;
}

.loginBigContent input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.loginBigContent input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.loginBigContent input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.loginBigContent input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.loginBigContent .form-group {
  position: relative;
}

.loginBigContent .input-icon {
  position: absolute;
  right: 0;
  bottom: 2em;
}

.loginSmallContent {
  flex: 3;
  padding: 0 25px;
}

.loginBigContent button.loginForgotPassword {
  border: none;
  background: transparent;
  padding: 0;
  position: relative;
  top: -10px;
  font-size: 11px;
}

.loginBigContent button.loginForgotPassword:hover {
  background: transparent;
  color: #000;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .loginSmallContent {
    display: none !important;
  }
}

.loginSmallContent h3,
.loginSmallContent p {
  color: #ffd33d;
}

.loginSmallContent button,
.loginBigContent button {
  background-color: transparent;
  color: #ffd33d;
  border: rgba(252, 197, 34, 0.5) 1px solid;
  padding: 5px 40px;
  border-radius: 0;
  transition: 0.3s ease;
}

.loginSmallContent button:hover {
  border: #ffd33d 1px solid;
  cursor: pointer;
}

.loginCard {
  position: absolute;
  top: -10%;
  padding-top: calc((100% - 60px) * 4 / 7 + 1px);
  width: calc((100% - 60px) * 4 / 7 + 1px);
  height: 0 !important;
  background-color: #ffd33d;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: background-color 0.3s;
}

@media screen and (max-width: 767px) {
  .loginCard {
    width: 100%;
    height: 100% !important;
    padding: 0;
    top: 0;
    bottom: 0;
    left: 0 !important;
    right: 0;
  }
}

.loginBigContent button {
  background: transparent;
  color: #414141;
  border: 1px solid #414141;
}

.loginBigContent button:hover {
  background: rgba(65, 65, 65, 0.3);
  color: white;
}

.loginAlert {
  border-color: #000 !important;
  background: rgba(65, 65, 65, 0.3);
  color: #fff;
  border-radius: 0 !important;
  padding: 10px !important;
}

.shiftRight {
  left: calc((100% - 60px) * 3 / 7 + 30px);
  animation: goRight 0.5s ease;
}

.shiftLeft {
  left: 30px;
  animation: goLeft 0.5s ease;
}

@media screen and (max-width: 767px) {
  .loginCard.shiftRight {
    background: rgba(40, 40, 41, 1);
  }

  .loginBigContent.is-visible .loginForm p,
  .loginBigContent.is-visible .loginForm h3,
  .loginBigContent.is-visible .loginForm button,
  .loginBigContent.is-visible .loginForm button:hover,
  .loginBigContent.is-visible .loginForm a,
  .loginBigContent.is-visible .loginForm input,
  .loginBigContent.is-visible .loginForm .loginAlert {
    color: #ffd33d;
    border-color: #ffd33d !important;
  }

  .loginBigContent.is-visible .loginForm .loginForgotPassword {
    color: #b59423;
  }

  .loginBigContent.is-visible .loginForm input::-webkit-input-placeholder {
    color: rgba(252, 197, 34, 0.5);
  }
  .loginBigContent.is-visible .loginForm input::-moz-placeholder {
    color: rgba(252, 197, 34, 0.5);
  }
  .loginBigContent.is-visible .loginForm input:-moz-placeholder {
    color: rgba(252, 197, 34, 0.5);
  }

  .loginBigContent.is-visible .loginForm input,
  .loginBigContent.is-visible .loginForm button,
  .loginBigContent.is-visible .loginForm .loginAlert {
    border-color: rgba(252, 197, 34, 0.5) !important;
  }

  .loginBigContent.is-visible .loginForm .anticon svg {
    fill: #ffd33d;
  }

  @keyframes autofill {
    to {
      color: #ffd33d;
      background: transparent;
    }
  }

  .loginAlert {
    margin: 0 !important;
  }

  .shiftRight,
  .shiftLeft {
    animation: none;
    left: 0;
  }

  .is-visible > .loginForm {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .loginBigContent {
    backface-visibility: hidden;
    position: relative;
    z-index: 5;
  }

  .loginSmallContent {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    transform: rotateY(180deg);
  }

  .loginDark > .loginBigContent.is-visible,
  .loginDark > .loginSmallContent.is-visible {
    backface-visibility: visible;
    transform: rotateY(180deg);
    animation: cd-rotate 0.5s;
  }

  .loginDark > .loginBigContent:not(.is-visible),
  .loginDark > .loginSmallContent:not(.is-visible) {
    transform: rotateY(0);
    animation: cd-rotate-inverse 0.5s;
  }
}

@keyframes goRight {
  0% {
    left: 30px;
  }
  30% {
    left: calc((100% - 60px) * 3 / 7 + 55px);
  }
  60% {
    left: calc((100% - 60px) * 3 / 7 + 20px);
  }
  85% {
    left: calc((100% - 60px) * 3 / 7 + 35px);
  }
  100% {
    left: calc((100% - 60px) * 3 / 7 + 30px);
  }
}

@keyframes goLeft {
  0% {
    left: calc((100% - 60px) * 3 / 7 + 30px);
  }
  30% {
    left: 5px;
  }
  60% {
    left: 40px;
  }
  85% {
    left: 25px;
  }
  100% {
    left: 30px;
  }
}

@keyframes cd-rotate {
  0% {
    transform: perspective(800px) rotateY(0);
  }

  70% {
    /* this creates the bounce effect */
    transform: perspective(800px) rotateY(200deg);
  }

  100% {
    transform: perspective(800px) rotateY(180deg);
  }
}

@keyframes cd-rotate-inverse {
  0% {
    transform: perspective(800px) rotateY(-180deg);
  }

  70% {
    transform: perspective(800px) rotateY(20deg);
  }

  100% {
    transform: perspective(800px) rotateY(0);
  }
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}
