#category-form {
  width: 100%;
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .image-display {
      position: relative;
      width: 250px;
      height: 250px;
      border: 1px solid $grey-light;
      display: flex;
      justify-content: center;
      align-items: center;
      background: lighten($grey-light, 5%);

      .no-image {
        color: darken($grey-light, 10%);
        font-size: 50px;
      }

      img {
        width: 100%;
        object-fit: contain;
      }

      .add-img-bubble {
        border: 1px dashed $grey;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include border-radius();
      }

      .remove-image-btn {
        position: absolute;
        top: 5px;
        right: 10px;
        color: $red;
        font-size: 25px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .category-fields {
      width: 200px;
      .label {
        margin: 0;
        padding: 0;
        color: $grey;
        font-weight: 100;
        font-size: 13px;
        margin-bottom: 5px;
      }

      .name-row,
      .synonyms-row,
      .stripe-row,
      .public-select,
      .volume {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
      }

      .public-volume-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;

        .ant-tag {
          margin-bottom: 10px;
        }
      }
    }
  }

  .form-actions {
    padding-top: 20px;
    border-top: 1px solid $grey-light;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}
