.start-end-time-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .start-end-time {
    margin-top: 20px;

    .label {
      color: $charcoal;
      font-size: 15px;
      font-weight: 100;
    }

    .time {
      display: flex;
      flex-direction: column;
    }

    .time-picker,
    .num-of-movers {
      width: 230px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    .num-of-mins {
      display: flex;
      height: 30px;
      justify-content: center;
      align-items: center;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in;
      background: lighten($yellow, 32%);
      width: 230px;

      .clock-icon {
        margin-right: 10px;
      }

      &.expanded {
        max-height: 50px;
        margin-top: 20px;
      }
    }

    .skip-moving-fee {
      margin-top: 20px;
      margin-left: 5px;
      * {
        font-size: 15px;
        font-weight: 100;
      }
    }
  }
}
