#inventory-items-details-page {
  .back-btn {
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }

  .details-card {
    background: $white;
    border: 1px solid $grey-light;
    padding: 0 45px;
    margin: 20px 0;

    .page-title {
      width: 100%;
      text-align: center;
      font-size: 28px;
      margin-top: 25px;
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $grey-light;
      padding: 10px 5px;

      .title {
        font-weight: 300;
        font-size: 24px;
      }
    }

    .info-group {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;

      .info-with-label {
        width: 25%;
        margin: 5px 0;

        .label {
          font-size: 10px;
          color: $grey;
          font-weight: 300;
        }

        .info {
          font-size: 14px;
          font-weight: 400;
          padding: 2px 0 0 8px;
        }

        .link {
          cursor: pointer;
          color: $blue;
        }
      }
    }
  }

  .basic-info {
    .item-identifiers,
    .physical-attributes,
    .other-info {
      margin: 30px 0;
    }
  }
}

.inventory-edit-item-modal-wrap {
  .form-input-section {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    .ant-radio-button {
      font-weight: 400;
    }

    input {
      border: 1px solid $grey-mid;
      border-radius: 5px;
      padding: 5px 10px;

      &:focus {
        border-color: $yellow;
      }
    }
  }
}
