#dashboard-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .page-title {
    font-size: 36px;
    font-weight: 300;
  }

  hr {
    width: 100%;
  }

  .page-contents {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .spin-container {
      height: 100%;
    }

    .section-title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }

    .appointments-info-container {
      width: 100%;
      display: flex;
    }
  }
}
