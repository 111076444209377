#customer-index-page {
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-bottom: 20px;
    }
  }

  .user-filters {
    display: flex;
    margin: 20px 0;

    .label {
      color: $charcoal;
      font-weight: 300;
      font-size: 15px;
    }

    .delinquent-button {
      display: flex;
      align-items: center;
    }

    .role-selector {
      margin-left: 10px;
    }
  }

  .customers-table {
    margin-top: 20px;
    .table-pagination-control {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      .control-buttons {
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
