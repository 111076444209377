.stripe-element {
  position: relative;
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }

  .spinning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
