.address-search-list {
  position: absolute;
  right: 25px;
  left: 25px;
  background: white;
  @include border-radius;
  padding: 10px !important;
  list-style: none;
  z-index: 10000;
  border: 1px solid darken($grey-light, 10%);

  .address-search-list-item {
    border-bottom: 1px solid darken($grey-light, 10%);
    min-height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }
}

.address-create-search-bar {
  .label {
    color: $grey;
    padding-left: 0;
    font-size: 15px;
    font-weight: 100;
  }

  input {
    border: 1px solid $yellow;
    @include border-radius();
    width: 100%;
    padding: 8px 10px;
    color: $charcoal;
  }
}

.geosuggest__suggests--hidden {
  display: none;
}
