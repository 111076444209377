.create-untracked-shipment-modal {
  .items-table {
    margin-bottom: 20px;
    @include box-shadow();
    table {
      width: 100%;
      th,
      td {
        border: 1px solid $grey-light;
        width: 25%;
        padding: 10px;
        font-weight: 100;
      }

      th {
        font-weight: 500;
        color: $grey;
      }

      td {
        .name {
          color: $blue;
        }

        .stock {
          color: $green;
        }
      }
    }
  }

  .create-untracked-shipment-error {
    margin-bottom: 20px;
  }

  .notes {
    .title {
      font-size: 13px;
    }
    margin-top: 20px;
  }
}
