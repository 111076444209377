.order-info {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey-light;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .left {
    .order-number {
      font-size: 30px;
      margin-bottom: 10px;
    }

    .label-value {
      display: flex;
      margin-bottom: 10px;

      .label-value-status {
        color: $white;
      }

      .field-label {
        color: $grey;
        margin-right: 10px;
      }
    }
  }

  .right {
    .order-button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
