#organization-index-page {
  .page-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 40px;
    border: none;
    font-weight: 300;
    align-items: flex-end;

    .page-title {
      font-size: 36px;
    }
  }

  .organizations-table {
    margin: 20px 0;
  }

  .spinning {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}
