.edit-user-details-modal {
  .field-input {
    margin-top: 20px;
    .label {
      color: $grey;
      font-weight: 300;
      font-size: 13px;
      margin: 0;
      padding: 0;
    }
    .ant-select.ant-select-single.ant-select-show-arrow {
      width: 100% !important;
    }
  }

  .action-buttons {
    margin-top: 20px;
    text-align: center;

    .ant-btn {
      margin: 0 5px;
    }
  }
}
