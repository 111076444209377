.wro-detail {
  padding: 16px 32px;
  background: $grey-background;

  .wro-detail-content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .wro-detail-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 16px;

      .header-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 850px) {
          &:not(:last-child) {
            margin: 0 16px 16px 0;
          }
        }

        .button-wrapper {
          @media screen and (min-width: 767px) {
            &:not(:last-child) {
              margin: 0 16px 0 0;
            }
          }

          @media screen and (max-width: 766px) {
            margin: 0 16px 16px 0;
          }

          .ant-btn {
            padding: 8px 16px;
            height: auto;
            border-radius: 2px;
          }

          &.green {
            .ant-btn {
              color: $white;
              border-color: $green;
              background-color: $green;

              &:hover {
                border-color: #57cfb1;
                background-color: #57cfb1;
              }
            }
          }
        }
      }

      .wro-id {
        font-size: 25px;
        font-weight: 600;
        margin: 0 24px 0 0;
      }

      .delete-button {
        border: 1px solid $red;

        @include border-radius($radius-big);
        @include sm {
          margin: 10px 0;
        }

        span {
          color: $red;
        }

        &:hover {
          background: $red;

          span {
            color: white;
          }
        }
      }
    }

    .top {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 0 48px;
      padding: 16px;
      border: 1px solid $grey-light;
      border-radius: 2px;
      background: $white;

      @include sm {
        flex-direction: column;
      }

      .shipment-summary-column {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media screen and (min-width: 426px) {
          justify-content: space-between;
        }

        @media screen and (min-width: 426px) {
          margin-right: 16px;
          width: 250px;
        }

        @include sm {
          margin-bottom: 16px;
        }

        .shipment-detail-wrapper {
          display: flex;
          flex-direction: column;

          &:not(:last-child) {
            margin: 0 0 24px;
          }

          .shipment-detail-label {
            font-size: 12px;
            color: $grey;
            font-weight: 500;
          }

          .shipment-detail {
            min-height: 32px;
          }

          .ant-input {
            width: 164px;
          }

          .ant-select {
            min-width: 164px;
          }

          .shipment-detail-value {
            font-size: 15px;
          }
        }
      }
    }

    .bottom {
      .order-items {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin: 0 0 96px;

          .ant-table-wrapper {
            border: 1px solid $grey-light;
            border-radius: 6px;
            overflow: auto;

            .ant-table {
              .ant-table-tbody {
                .ant-table-row:last-child {
                  .ant-table-cell {
                    border-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .table-header-packaging-option {
          font-size: 15px;
          font-weight: 600;
        }

        label {
          color: $grey;
          font-size: 16px;
          align-items: center;

          &:first-child {
            margin: 0 0 8px;
          }
        }

        .package-items-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 0 16px;

          .header-section {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            label {
              margin: 0 48px 0 0;

              @media screen and (max-width: 426px) {
                margin: 0 0 16px 0;
              }
            }

            .checkbox-wrapper {
              @media screen and (max-width: 426px) {
                margin: 0 0 8px 0;
              }

              @media screen and (min-width: 426px) {
                margin: 0 8px 0 0;
              }

              label {
                color: $charcoal;
                font-size: 15px;
                font-weight: 500;
                margin: 0;
              }
            }

            .button-wrapper {
              &:not(:last-child) {
                margin: 0 16px 0 0;
              }

              @media screen and (max-width: 426px) {
                margin-top: 16px;
              }

              .ant-btn {
                padding: 8px 16px;
                height: auto;
                border-radius: 2px;
              }

              &.green {
                .ant-btn {
                  color: $white;
                  border-color: $green;
                  background-color: $green;

                  &:hover {
                    border-color: #57cfb1;
                    background-color: #57cfb1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.loader {
  width: 100%;
  min-height: 79.9vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
