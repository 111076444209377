.select-return-reason {
  @include box-shadow();

  .items-table {
    table {
      width: 100%;
      th,
      td {
        border: 1px solid $grey-light;
        padding: 10px;
      }

      th {
        font-weight: 500;
        color: $grey;
      }
    }
  }
}
