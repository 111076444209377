#storage-order-details-page {
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    @include box-shadow();
    padding: 20px;
    margin-bottom: 10px;
    * {
      white-space: nowrap;
    }

    .title {
      font-size: 20px;
      color: $charcoal;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $grey-light;
    }
  }

  .order-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .left {
      flex: 1;
      margin-right: 10px;
      .user-info {
        .email,
        .name {
          margin-top: 10px;
        }
      }
    }

    .right {
      flex: 0.8;
    }

    @include md {
      flex-direction: column;
      .left {
        margin-right: 0;
      }
    }
  }

  .other-info {
    .option-row {
      margin-top: 20px;

      &.notes {
        border-bottom: 1px solid $grey-light;
        padding-bottom: 20px;
      }
    }

    .order-notes {
      max-width: 500px;
      white-space: normal;
    }
  }
}
