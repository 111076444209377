.appointment-action-buttons-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .button-wrap {
    margin-right: 10px;
    .ant-btn {
      margin-bottom: 10px;
    }
  }
}
