.customer-closet-card {
  @include box-shadow();
  padding: 20px;
  min-height: 400px;

  .title {
    font-size: 25px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    button {
      margin-left: 5px;
      background: white;
      color: $red;
      border: 1px solid $red;

      &:hover {
        background: $red;
        color: white;
      }
    }
  }

  .instruction {
    color: $grey;
  }

  .spinner {
    width: 100%;
    text-align: center;
  }

  .closet-search {
    margin-bottom: 10px;
  }

  .return-item-table {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;

    .table-header {
      display: flex;
      flex-direction: row;
      padding: 10px;

      div {
        display: flex;
        width: 20%;
        color: $grey;
      }
      border-bottom: 1px solid $grey-light;
    }

    .return-item-row {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;

      div {
        width: 20%;
        padding: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      img {
        width: 50px;
      }

      &:hover {
        background: lighten($grey-light, 3%);
        cursor: pointer;

        .return-icon {
          background: rgba($blue, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: white;
            margin-right: 10px;
            font-weight: 600;
          }

          img {
            width: 20px;
          }
        }
      }

      .return-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: white;
      }
    }
  }

  .regret-zone {
    margin-top: 20px;

    .zone-scroll {
      max-height: 400px;
      overflow-y: auto;
      .return-items {
        display: flex;
        flex-direction: column;
        padding-left: 5px;

        .return-item {
          display: flex;
          flex-direction: row;
          font-size: 13px;
          align-items: center;
          color: $grey;
          padding: 5px 0;
          border-bottom: 1px solid $grey-light;

          div {
            margin-right: 20px;

            i {
              color: lighten($grey, 20%);
            }
          }

          button {
            background: none;
            border: none;
            background: $grey;
            color: white;
            border-radius: 5px;
            font-weight: 100;
            transition: background 0.2s ease;
            font-size: 12px;
            padding: 2px 10px;

            &:hover {
              background: darken($grey, 10%);
            }
          }
        }
      }
    }
  }

  .return-all-button {
    margin-bottom: 10px;
  }
}
