.upload-logo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.disabled {
    cursor: not-allowed;
    opacity: 1;
    & .description {
      color: $grey;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-label {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $grey;
    font-size: 14px;
    font-weight: 100;
  }

  .upload-zone {
    margin-bottom: 10px;
    border: 1px dashed $grey;
    background: lighten($grey-light, 5%);
    display: flex;
    justify-content: center;
    align-items: center;

    &.no-border {
      border: none;
    }

    &:hover {
      border-color: $blue;
    }

    .photo-add-button {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px;
      color: $grey;

      .upload-icon {
        font-size: 30px;
        margin-bottom: 10px;
      }

      &:hover {
        color: $blue;
      }
    }

    .image-wrapper {
      width: 100%;
      padding: 0;

      img {
        width: 100%;
      }
    }
  }

  .remove-button-wrapper {
    display: flex;
    justify-content: center;
    margin: 8px 0 0;

    button {
      border-radius: 6px;
    }
  }
}
