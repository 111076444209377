.failed-appointment-banner {
  width: 100%;
  margin-bottom: 20px;
  background: #fff1f0;
  padding: 20px;

  .banner-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: $red;
  }

  .reschedule-message {
    margin-top: 20px;
    color: $green;
  }

  .recreate-button-row {
    margin-top: 20px;

    .rescheduled-appt-btn {
      background-color: $green;
      color: $white;
      border-radius: 5px;
      padding: 6px 15px;
      width: fit-content;

      &:hover {
        border-color: $green;
      }
    }
  }
}

.recreate-appointment-modal {
  flex-wrap: wrap;
}
