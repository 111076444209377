.appointment-edit-name-modal {
  $mobile-width: 576px;

  table {
    width: 100%;
    margin-bottom: 15px;

    tr {
      border-bottom: 1px solid $grey-light;
      transition: all 300ms;

      &:hover {
        background-color: lighten($blue, 37%);
      }
    }

    th,
    td {
      padding: 15px 10px;
      font-weight: 400;
    }

    th {
      color: darken($grey, 5%);
    }

    td {
      color: $grey;
    }

    .description-column {
      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    .status-column {
      text-align: center;
    }

    .error {
      border: 1px solid $red;
      @include border-radius();
    }

    .green {
      color: $green;
    }

    .red {
      color: $red;
    }
  }

  .flex-row {
    margin-top: 15px;
    justify-content: center;
  }
}
