%btn-shadow-blue,
.btn-shadow-blue {
  @include box-shadow();
  @include transition(all 0.35s);
  border-radius: 0;
  background-color: $white;
  color: $blue;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  > img {
    width: 18px;
    margin-right: 2px;
  }
  &:hover {
    color: $blue;
    @include box-shadow(rgba(black, 0.4));
  }
}

%btn-small,
.btn-small {
  background: transparent;
  border: none;
  padding: 0;
  font-size: 11px;
  font-weight: 400;
  color: $grey;
  @include transition(color 0.3s);
  &:hover {
    color: $blue;
  }
}

.blue-btn,
.yellow-btn,
.red-btn {
  padding: 10px 20px;
  @include box-shadow();
  @include transition(box-shadow 0.3s);

  &:hover {
    @include box-shadow(rgba(black, 0.5));
    cursor: pointer;
  }

  &.disabled {
    background: $grey;
  }
}

.blue-btn {
  background: $blue;
  color: $white;
}

.red-btn {
  background: $red;
  color: $white;
}

.yellow-btn {
  background: $yellow;
  color: $charcoal;
}
