.address-form {
  @include sm {
    margin: 0 0 60px;
  }

  .address-form-row {
    display: flex;
    margin: 0 0 32px;

    input:not(:last-child) {
      margin: 0 12px 0 0;
    }

    .address-line-1 {
      flex: 1;
    }

    .unit-number {
      max-width: 75px;
    }

    .postal-code {
      min-width: 100px;
      max-width: 150px;
    }

    .province {
      max-width: 400px;
    }
  }
}
