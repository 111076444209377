#invoice-list-page {
  .empty-invoice {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .InvoiceTable {
    width: 100%;
    border-spacing: 0 10px;
    border-collapse: separate;
    button.invoiceBtn {
      padding: 5px 10px;
      color: $charcoal;
      background: $grey-light;
      transition: background 0.3s;
    }
    .invoiceBtn {
      padding: 0px 5px;
      border: none;
      border-radius: 20px;
      color: $white;
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      &.blue {
        background: rgba($blue, 0.5);
      }
      &.green {
        background: rgba($green, 0.5);
      }
      &.orange {
        background: rgba($orange, 0.5);
      }
      &.grey {
        background: rgba($grey, 0.7);
      }
      &.red {
        background: $red;
      }
    }
    td,
    th {
      width: 20%;
      padding-left: 1em;
      @include md {
        width: 18%;
        padding-left: 0.4em;
        &:nth-child(3) {
          width: 32%;
        }
      }
    }
    td {
      @include md {
        font-size: 12px;
      }
    }
    th {
      padding-bottom: 0.5em;
    }
    tr {
      &:hover button.invoiceBtn {
        background: $yellow;
      }
      &:not(:first-child) {
        height: 40px;
        background: $white;
        margin: 10px;
        @include box-shadow();
        @include transition(background 0.3s);
        &:hover {
          background: rgba($black, 0.02);
          cursor: pointer;
        }
      }
    }
  }
}
