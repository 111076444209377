#image-box {
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-light;

  .default-icon {
    font-size: 50px;
    color: $grey;
  }

  .image-square {
    flex: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .description {
    flex: 0.1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $grey;
  }

  &:hover {
    @include box-shadow();
    border: none;
    cursor: pointer;
  }
}
