.order-edit-summary {
  margin-bottom: 20px;
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    margin-bottom: 20px;
  }

  border-bottom: 1px solid darken($grey-light, 10%);
  .title {
    font-size: 25px;
    border-bottom: 1px solid darken($grey-light, 10%);
  }

  .summary-items {
    padding: 10px 0;
    .item {
      font-size: 15px;
      margin-top: 5px;
      span {
        color: lighten($grey, 10%);
        font-size: 12px;
      }
    }
  }

  .coupon-field {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 20px;

    .coupon-wrap {
      width: 200px;
      .label {
        color: $grey;
        font-size: 12px;
        font-weight: 300;
      }

      .coupon-display {
        display: flex;
        align-items: center;

        .coupon-value {
          margin-left: 10px;
        }

        .close-btn {
          margin-left: 5px;
        }
      }

      .coupon-action-group {
        width: 200px;
        display: flex;
        justify-content: space-between;

        .apply-button {
          margin-left: 5px;
        }
      }
    }
  }

  .money-breakdown {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;

    @include sm {
      justify-content: flex-start;
    }

    .money-row {
      width: 200px;
      display: flex;
      justify-content: space-between;

      .label {
        color: $charcoal;
        font-weight: 100;
        font-size: 15px;
      }
    }
  }
}
