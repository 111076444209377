.shopify-install-list {
  .header {
    font-size: 21px;
    font-weight: 500;
  }

  .sync-order-wrapper {
    display: flex;
    flex-direction: row;

    .sync-order-input {
      margin: 0 16px 0 0;
      width: 160px;
    }
  }

  .sync-shop-button {
    color: $grey;
    border: 0;
    background-color: transparent;

    &:hover {
      color: $grey;
      cursor: pointer;
    }
  }
}
