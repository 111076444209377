.appointment-detail-info-card {
  width: 100%;
  border: 1px solid $yellow;
  border-top: 10px solid $yellow;

  .header {
    padding-bottom: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-light;

    .appointment-number {
      font-size: 23px;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      span {
        margin-right: 5px;
      }
    }

    .force-complete {
      color: $grey;
      font-size: 15px;
      display: flex;
      align-items: center;

      .ant-btn {
        margin-left: 10px;
      }
    }
  }

  .appointment-controls {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @include md {
      flex-direction: column;
      justify-content: flex-start;
    }

    .left,
    .mid,
    .right {
      margin: 20px 0;
      flex: 1;
      @include md {
        text-align: center;
        margin: 20px auto;
      }
    }

    .left,
    .mid {
      border-right: 1px solid $grey-light;

      @include md {
        border-right: none;
      }
    }

    .mid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $grey;

      .value {
        font-size: 25px;
      }

      .big-text {
        font-size: 18px;
        text-align: center;
        font-weight: 100;
      }
    }

    .appointment-status,
    .appointment-type,
    .appointment-order,
    .appointment-service-area,
    .appointment-customer-segment,
    .appointment-subtype {
      margin: 10px 0;
      font-size: 15px;
      color: $grey;
    }

    .appointment-signature {
      margin-top: 10px;
      padding-left: 20px;

      img {
        width: 150px;
      }

      .appointment-signature-description {
        font-size: 12px;
        color: $grey;
        border-top: 1px solid $grey-light;
      }
    }

    .appointment-moving-time {
      padding: 10px 20px;
      .time-row {
        width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
          font-size: 15px;
          color: $grey;
          font-weight: 300;
          margin: 0;
          padding: 0;
          text-align: left;
          margin-right: 10px;
        }
      }

      .mover-row {
        margin-top: 10px;
        width: 200px;
        .label {
          font-size: 13px;
          color: $grey;
          font-weight: 100;
          margin: 0;
          padding: 0;
          text-align: left;
        }
      }
    }
  }
}

.status-change-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
