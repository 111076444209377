.select-address-card {
  width: 100%;
  @include box-shadow();
  padding: 20px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 25px;
  }

  .instruction {
    color: $grey;
  }

  .new-address-button {
    border: none;
    width: 100px;
    padding-left: 5px;
    box-shadow: none;
    margin-top: 10px;
  }
}
