.order-items {
  .item-row {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
    border-bottom: 1px solid $grey-light;

    .item-row-cell {
      width: 33%;
      display: flex;
      &:not(:first-child) {
        justify-content: flex-end;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 15px;
    }

    .unit-price {
      color: $grey;
      font-size: 12px;
    }
  }

  .summary {
    padding-top: 20px;
    .price-label-value {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;

      .price-label,
      .value {
        display: flex;
        width: 33%;
        justify-content: flex-end;
        font-size: 14px;
      }

      .price-label {
        color: $charcoal;
      }

      &.total {
        * {
          font-weight: 600;
          color: $green;
        }
      }
    }
  }
}
