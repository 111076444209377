.text-blue {
  color: $blue;
}

.text-grey {
  color: $grey;
}

.text-black {
  color: $charcoal;
}

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}

.text-white {
  color: $white;
}

@include md {
  .text-left-md {
    text-align: left !important;
  }
  .text-center-md {
    text-align: center !important;
  }
  .text-right-md {
    text-align: right !important;
  }
}

@include sm {
  .text-left-sm {
    text-align: left !important;
  }
  .text-center-sm {
    text-align: center !important;
  }
  .text-right-sm {
    text-align: right !important;
  }
}

.text-small {
  font-size: 11px;
}
