.item-filter-button {
  margin-right: 10px;
  display: flex;
  @include border-radius();
  border: 1px solid $blue;
  transition: all 0.1s ease-in;
  overflow: hidden;
  box-shadow: none;
  user-select: none;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
    @include box-shadow;
  }

  &.selected {
    border: 1px solid $yellow;
    box-shadow: none;
    .button-count {
      background: $yellow;
    }
  }

  .button-text {
    text-transform: uppercase;
    padding: 5px 0;
    padding-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
  }

  .button-count {
    transition: all 0.15s ease-in;
    background: $blue;
    color: white;
    padding: 5px 10px;
  }
}
