#category-management {
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin-bottom: 20px;
    }
  }

  .category-table {
    margin-top: 20px;

    .ant-table td {
      white-space: nowrap;
    }

    .table-pagination-control {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      .control-buttons {
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .edit-button {
      border: none;
      background: none;
      color: $blue;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.category-popover-list p {
  font-size: 12px;
  color: #aaa;
  font-weight: 100;
  margin: 0;
}
