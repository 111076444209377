.fulfillment-table-big {
  @include transition(all 0.3s);
  @include box-shadow();

  * {
    @include scrollbar();
  }

  th {
    background: white !important;
    border-bottom: 1px solid darken($grey-light, 5%) !important;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    margin: 0;

    span {
      margin: 0 5px;
    }
  }

  .view-detail-btn {
    color: $blue;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.no-fulfillment {
    .no-data {
      padding: 25px 0;
    }

    img {
      margin-bottom: 20px;
    }

    p {
      margin: 0;
      color: $grey;
    }
  }

  .ant-table-expanded-row-fixed {
    position: relative !important;
    overflow: initial !important;
  }
}
