.customer-notes {
  margin-top: 20px;
  border: 1px solid $grey-light;
  padding: 20px;

  .notes-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 10px;

    .title {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
}
