.billing-details-card {
  @include box-shadow();
  padding: 20px;

  .title {
    font-size: 25px;
  }

  .edit-button {
    border: none;
    padding: 0;
    box-shadow: none;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80px;
    margin: 10px 0;

    .info-line {
      font-size: 15px;
    }

    &.spinning {
      justify-content: center;
      align-items: center;
    }

    &.no-data {
      height: 30px;

      span {
        font-size: 13px;
        color: $grey;
      }
    }
  }
}
