.inventory-item-color-tag {
  $square-width: 10px;

  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
  }

  .square {
    width: $square-width;
    height: $square-width;
    margin-right: 7px;
  }
}
