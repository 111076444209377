.select-new-supply-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .product-column {
    margin-top: 20px;
    flex-grow: 1;
    min-width: 250px;
    .column-title {
      padding-bottom: 10px;
      border-bottom: 1px solid $grey-light;
      margin-bottom: 10px;
      font-size: 12px;
      min-height: 30px;
    }
  }
  .product-controller {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .plus-minus-input {
      margin-right: 10px;
    }

    .product-detail {
      display: inline-flex;
      flex-direction: column;

      .product-name {
        font-size: 12px;
      }

      .product-price {
        font-size: 10px;
        color: $grey;
      }
    }
  }
}
