.appointment-overview {
  display: flex;
  padding: 40px 20px;
  align-items: center;
  width: 70%;
  margin: auto;

  .summary-cards-with-barchart {
    margin-left: 25px;
    box-sizing: border-box;
    width: 100%;

    .summary-cards-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto 40px;

      .daily-summary-card {
        cursor: pointer;
        text-align: center;
        background: $grey-light;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        margin: 1% auto;
        position: relative;

        @include lg {
          width: 49%;
        }

        @include md {
          display: none;
        }

        &:hover {
          &::after {
            content: "";
            background: $black;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.1;
          }
        }

        .date-appointment-count {
          margin: 10px;

          .date {
            font-size: 24px;
          }

          .appointment-count {
            font-weight: 700;
            font-size: 36px;
          }

          .heavy-threshold {
            color: #ed5565;
          }
        }

        .appointments-by-timeslot {
          display: flex;

          .appointments-in-timeslot {
            flex: 0.34;
            border: 1px solid $grey-mid;

            .time-label {
              font-size: 10px;
              font-weight: 400;
              margin: 0;
            }

            .timeslot-appointment-count {
              font-weight: 600;
              margin-bottom: 2px;
            }
          }
        }
      }
    }

    .barchart-wrapper {
      margin: auto;
      height: 18vh;
      width: 95%;

      @include md {
        display: none;
      }
    }
  }
}
