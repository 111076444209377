#customer-inventory-page {
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 35px;
    font-weight: 100;
    margin-bottom: 20px;
  }

  .closet-filter {
    &.spinning {
      display: flex;
      min-height: 50px;
      justify-content: center;
    }

    .closet-search {
      margin: 10px 0;
    }
  }

  .closet-volume-bar {
    .bar {
      width: 200px;
    }

    .loading-icon {
      color: $blue;
    }
  }

  .item-filters {
    display: flex;
    flex-wrap: wrap;
  }

  .progress-bar-wrap {
    display: flex;
    align-items: center;
    .percent-text {
      color: $grey;
      margin-left: 10px;
    }
  }
}

.plan-row {
  display: flex;
  justify-content: space-between;

  .ant-tag {
    margin-left: 10px;
  }
}
