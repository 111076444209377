.inventory-table {
  &.spinning {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }

  .closet-action {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .closet-search {
      width: 400px;
      margin: 5px 0;
    }

    .closet-buttons {
      margin-bottom: 10px;
      .move-items-dropdown {
        margin-right: 10px;
      }
    }
  }

  .ant-table td {
    white-space: nowrap;
  }
}

.new-closet-row {
  display: flex;
  justify-content: space-between;
  .ant-tag {
    margin-left: 20px;
  }
  span {
    font-size: 11px;
    color: $grey;
  }
}

.move-items-to-new-closet-modal {
  display: flex;
  flex-direction: column;

  * {
    white-space: nowrap;
  }

  .title,
  .item-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    div {
      flex-basis: 33%;

      &.status {
        flex-basis: 25%;
      }
    }
  }

  .title {
    border-bottom: 1px solid $grey-light;
    margin-bottom: 5px;
    div {
      color: $grey;
    }
  }
}
