#shipment-upload-csv {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title {
    font-size: 30px;
    font-weight: 300;
  }

  .instructions {
    color: $grey;
    margin: 30px auto;
    max-width: 400px;
    text-align: center;
  }

  .grey-link {
    color: $grey;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;

    .uploader {
      margin: 40px 0;

      .ant-upload {
        width: 550px;
        height: 200px;
      }
    }
  }

  .shipments-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
}
