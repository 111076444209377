.sync-order-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .search-section {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    .search-bar-wrap {
      position: relative;
      width: 500px;

      .description {
        color: $blue;
        font-size: 14px;
      }

      .input-row {
        display: flex;
        margin: 0.25em 0;
        .ant-btn {
          margin-left: 10px;
        }
      }
    }
  }

  .orders-table-wrap {
    width: 100%;
    margin: auto;
    max-width: 1000px;
    margin-top: 80px;
  }
}

.guide {
  display: flex;
  flex-direction: column;
  place-content: "center";
  img {
    width: 100%;
    max-width: 700px;
  }
}
