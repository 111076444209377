.select-product-cards {
  &.spinning {
    width: 100%;
    height: 100px;
    @include box-shadow();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.empty {
    width: 100%;
    height: 100px;
    @include box-shadow();
    padding: 20px;
  }

  * {
    user-select: none;
  }

  .instruction {
    color: $grey;
  }

  display: flex;
  flex-direction: column;
  .product-card,
  .supplies-card {
    @include box-shadow();
    padding: 20px;

    .title {
      font-size: 25px;
    }
  }

  .product-card {
    margin-bottom: 20px;
  }
}
