.customer-addresses {
  margin-top: 20px;
  border: 1px solid $grey-light;
  padding: 20px;

  .address-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 10px;
    .title {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  .address-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .address-block {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $grey;
      font-size: 16px;
      width: 300px;
      padding: 16px 16px 0;
    }

    .no-address {
      margin-top: 20px;
    }
  }

  @include md {
    .address-blocks {
      flex-direction: column;
      align-items: center;
    }
  }
}
