.photo-manager {
  background: white;
  padding: 0 30px;

  .photo-controller {
    p {
      font-size: 23px;
      color: $charcoal;
      font-weight: 100;
      margin-top: 20px;
      text-transform: uppercase;
      padding-bottom: 10px;
      border-bottom: 1px solid $grey-light;
    }

    .bubbles {
      display: flex;
      flex-wrap: wrap;

      .image-box {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
        margin-bottom: 10px;

        .actual-image {
          width: 50px;
          height: 50px;
        }

        .trash-can {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: none;
        }

        &:hover {
          cursor: pointer;
          .trash-can {
            background: rgba($red, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .add-img-bubble {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed $blue;
        font-size: 15px;
        color: $blue;

        &:hover {
          border: 1px dashed $blue;
          cursor: pointer;
          background: rgba($blue, 0.1);
        }
      }
    }
  }
}

.modal-image {
  width: 100%;
}

.modal-image + p {
  text-align: center;
  margin-top: 20px;
}

.delete-button-row {
  margin-top: 10px;
}
