.customer-appointments-table {
  border: 1px solid $grey-light;
  padding: 20px;
  margin-top: 20px;

  .city-filter {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .ant-table {
    border: none;
    padding: none;
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey-light;

    .title {
      font-size: 20px;
    }
    .action-buttons {
      display: flex;
      align-items: center;

      .create-order-dropdown {
        margin-left: 10px;
      }
    }

    @include md {
      flex-direction: column;
      justify-content: flex-start;

      .action-buttons {
        margin-top: 10px;
      }
    }
  }
}
