.new-address-modal {
  display: flex;
  flex-direction: column;

  .address-details {
    display: flex;
    flex-wrap: wrap;

    .address-field {
      margin-top: 20px;
      width: 50%;

      .label {
        font-size: 12px;
        color: $grey;
      }

      @include sm {
        width: 100%;
      }

      input {
        width: 200px;
        padding: 8px 10px;
        border: 2px solid $green;
        @include border-radius();

        @include sm {
          width: 100%;
        }

        &.error {
          border: 2px solid $red;
        }
      }
    }
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .phone-field,
    .ext-field,
    .residence-type,
    .ground-floor,
    .elevator-access {
      margin-top: 20px;
      width: 50%;

      .label {
        font-size: 12px;
        color: $grey;
        font-weight: 100;
      }

      @include sm {
        width: 100%;
      }
    }

    .ext-field {
      input {
        width: 200px;
        padding: 6px 10px;
        border: 1px solid $grey-mid;
        @include border-radius();

        @include sm {
          width: 100%;
        }
      }
    }

    .select-dropdown {
      min-width: 200px;
      @include sm {
        width: 100%;
      }
    }
  }

  .help {
    margin-top: 20px;
    text-align: center;

    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .description,
  .address-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .description {
    color: $blue;
  }

  .address-preview {
    flex-direction: column;
  }
}

.address-search-form {
  display: flex;
  flex-direction: column;

  .address-search {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      border: 1px solid $yellow;
      @include border-radius();
      width: 100%;
      padding: 8px 10px;
      color: $charcoal;
    }

    .label {
      font-size: 10px;
      color: $grey;
    }

    .unit-input {
      width: 100px;
      margin-left: 20px;

      .label {
        font-weight: 100;
      }
    }

    .address-search-bar {
      flex: 1;

      .label {
        font-weight: 100;
      }

      .address-search-list {
        position: absolute;
        background: white;
        @include border-radius;
        padding: 10px;
        list-style: none;
        z-index: 10000;
        border: 1px solid $grey-mid;

        .address-search-list-item {
          border-bottom: 1px solid $grey-mid;
          min-height: 35px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 0;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            cursor: pointer;
            color: $blue;
          }
        }
      }
    }
  }
}

.geosuggest__suggests--hidden {
  display: none;
}

.react-tel-input {
  input {
    &.form-control {
      width: 200px;
      @include border-radius();
      @include sm {
        width: 100%;
      }
    }
  }

  .country-list {
    margin-top: 1px;
  }
}

#alert-banner {
  margin: 0 20px 10px 0;
  text-align: left;
}
