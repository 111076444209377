.customer-info-section {
  .name-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .customer-name {
      font-size: 25px;
      span {
        font-size: 20px;
        color: $grey;
      }
    }

    .link-buttons {
      display: flex;

      .ant-btn {
        margin-left: 10px;
      }
    }

    @include md {
      flex-direction: column;
      .link-buttons {
        margin-top: 10px;
      }
    }
  }

  .delinquent-banner {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    background: $red;
    color: white;
    padding: 2px;
    border-radius: 13px;

    .bell-icon {
      margin-right: 10px;
    }

    @include md {
      margin: auto;
      margin-top: 20px;
    }
  }

  .customer-information {
    margin-top: 20px;
    border: 1px solid $grey-light;
    display: flex;

    .left,
    .right {
      flex-basis: 50%;
      padding: 20px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey-light;
        padding-bottom: 10px;
        color: $grey;
        font-size: 17px;
      }
    }

    .left {
      border-right: 1px solid $grey-light;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include md {
      flex-direction: column;
      .left {
        border-right: none;
      }
    }

    .contact-information,
    .user-type,
    .billing,
    .user-roles {
      .label-value {
        margin-top: 10px;
        display: flex;
        align-items: center;

        .label {
          font-size: 13px;
          color: $grey;
          padding-left: 0;
          font-weight: 500;
        }

        .value {
          font-size: 13px;
          color: $charcoal;
          margin-left: 5px;

          &.organization-link {
            color: $blue;
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .user-type,
    .user-roles {
      margin-top: 40px;
    }

    .billing {
      .credit-card-display {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .credit-card-icon {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }
}
