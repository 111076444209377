.summary-sheet {
  color: $white;
  background: $charcoal;
  padding: 40px;
  width: 35%;

  @include md {
    width: 100%;
    flex: 1;
    margin: 20px auto;
  }

  .selected-date {
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 25px;
  }

  .appointment-type-total {
    font-weight: 700;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .appointment-type-tag {
      text-align: center;
      flex-grow: 1;
      padding: 3px;
      border-radius: 3px;
      min-width: 160px;
    }

    .appointment-stat {
      margin-left: 20px;
    }
  }
}
