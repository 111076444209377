.closet-name-modifier {
  margin: 20px 0;
  font-size: 28px;
  font-weight: 100;
  min-height: 20px;
  display: flex;
  align-items: center;

  .closet-full-name {
    span {
      font-size: 12px;
      color: $grey;
    }
  }
  .name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    input {
      width: 200px;
    }

    .ant-tag {
      margin-left: 5px;
    }

    .closet-quantity {
      font-size: 13px;
    }

    .plans-dropdown {
      margin-right: 10px;
    }
  }
}

.update-plan-modal {
  .plan-row {
    margin: 20px 0;
    display: flex;
    flex-direction: column;

    .new::before {
      content: "*";
      color: $blue;
      margin-right: 5px;
    }

    label {
      color: $charcoal;
      font-weight: 100;
    }
  }
}
