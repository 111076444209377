.alert-smooth-box {
  margin-top: 10px;
  height: auto;
  @include transform(scaleY(1));
  @include transition(transform 0.1s, height 0.1s);
  &.disabled {
    @include transform(scaleY(0));
    height: 0px;
  }
}
