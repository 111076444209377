.fulfillment-create-order {
  .client-customer-information {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
      &:focus {
        outline: none;
      }
    }

    .flex-row {
      align-content: center;
      justify-content: center;
    }

    .form-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      #customer-information-form {
        width: 100%;
        max-width: 800px;
        .title {
          margin-top: 20px;
        }

        .form-input-box {
          width: 100%;
          height: 32px;
          margin-top: 10px;
          font-size: 13px !important;
          padding: 5px 10px;

          &::placeholder {
            color: $grey;
            font-size: 13px;
          }
        }

        .country-select-dropdown,
        .split {
          width: 100%;
          margin-top: 10px;
        }

        .split {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .form-input-box {
            margin-top: 0;
            width: 25%;
            min-width: 150px;
            margin-left: 10px;
          }

          .province-select {
            width: 75%;
            input {
              font-size: 13px !important;

              &::placeholder {
                color: $grey;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
