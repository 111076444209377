.select-appointment-date {
  @include box-shadow();
  padding: 20px;

  &.spinning {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .instruction {
    color: $grey;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    .ant-tag {
      margin-left: 10px;
    }
  }

  .description {
    font-size: 12px;
    color: $grey;
    margin-bottom: 10px;
  }

  .appointment-dates {
    display: flex;
    flex-direction: row;

    .single-date {
      width: 50%;
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-right: 20px;
      }

      .date-title {
        margin: 10px 0;
        font-weight: 600;
      }

      .time-slot-btns {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        button {
          margin-top: 10px;
        }
      }
    }
  }
}
