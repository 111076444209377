.textInputContainer {
  position: relative;
}

.textInputContainer > ul {
  list-style-type: none;
  overflow: scroll;
  visibility: hidden;
  z-index: 5;
  opacity: 0;
  position: absolute;
  width: 100%;
  max-height: 0;
  padding: 15px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
}

.textInputContainer > input:focus ~ ul {
  visibility: visible;
  max-height: 200px;
  opacity: 1;
  transition-delay: 0s, 0s, 0.3s;
}
