.select-new-space-items-card {
  width: 100%;
  .title {
    font-size: 25px;

    &.small {
      font-size: 20px;
      color: $charcoal;
    }
  }

  .item-card-search {
    width: 100%;
    text-align: center;
    .item-cart-dropdown {
      padding: 10px;
      border: 1px solid lighten($grey, 15%);
      border-top: none;
      border-radius: 4px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      max-height: 300px;
      overflow-y: auto;

      .category-row {
        padding: 10px 0;
        border-bottom: 1px solid $grey-light;
        color: $grey;

        &:hover {
          cursor: pointer;
          background: $grey-light;
        }

        &:last-child() {
          border-bottom: none;
        }
      }
    }
  }

  .added-items {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .added-item-row {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 1fr auto;
      margin: 4px;

      .added-item-name {
        color: $charcoal;
      }
    }
  }
}
