#shipment-index-page {
  .ant-table {
    font-size: 16px;
  }
  .ant-table-thead > tr > th {
    font-size: 14px;
    color: #8c8c8c;
  }
  .ant-table-cell .ant-typography,
  a {
    font-size: 14px;
  }

  tr > td .ant-space-item {
    line-height: 1;
  }
  #file-upload {
    display: none;
  }

  .org-name-with-btns {
    display: flex;
    justify-content: space-between;

    .organization-name {
      padding: 0;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .header-btns {
      display: flex;

      .upload-csv-btn {
        border-radius: 15px;
        border: 1px solid $blue;
        margin-right: 10px;

        span {
          color: $blue;
        }

        &:hover {
          background: $blue;
          span {
            color: white;
          }
        }
      }
    }
  }

  .shipments-header {
    .title-wrapper {
      display: flex;
      align-items: center;
      .header-btns {
        margin-left: auto;
        button {
          margin-left: 1em;
        }
      }
    }
    .title {
      font-size: 24px;
      margin-top: 0;
    }

    .shipment-filters {
      margin-bottom: 1.5em;
      .filter-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .filter {
          display: flex;
          padding-right: 20px;
          align-items: center;
          margin-top: 10px;

          .label {
            padding: 0 0.6em 0 0;
            font-size: 14px;
            font-weight: 400;
            color: $grey;
          }

          button.org.ant-input-search-button {
            color: rgba(0, 0, 0, 0.45);
            border-radius: 0 2px 2px 0;
            vertical-align: -3px;
            &:not(:hover) {
              border-left: none;
            }
          }
        }
      }
    }
  }
  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
